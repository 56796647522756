<script>
import SqInputField from 'pages/JsonToForm/Fields/SqInputField.vue'
import SqMarkdownDisplay from 'components/Common/SqMarkdownDisplay.vue'
import SingleFieldConfigByType from 'pages/JsonToForm/Fields/DynamicBuilder/SingleFieldConfigByType.vue'
import {
  FIELD_TYPE_INPUT,
  FIELD_TYPE_INPUT_NUMBER,
  FIELD_TYPE_TEXT_AREA,
  FIELD_TYPE_CHECKBOX,
  FIELD_TYPE_SWITCH,
  FIELD_TYPE_DATE_PICKER
} from 'pages/JsonToForm/utils'
import ConfirmationDialog from 'components/Functional/ConfirmationDialog.vue'

export default {
  name: 'SqObjectArray',

  components: { ConfirmationDialog, SingleFieldConfigByType, SqMarkdownDisplay, SqInputField },

  props: {
    modelValue: {
      required: false
    },

    properties: {
      type: Array,
      required: false,
      default: []
    },

    label: {
      type: String,
      required: false,
      default: ''
    }
  },

  emits: ['update:model-value'],

  data() {
    return {
      propertyValues: [],
      showRemoveConfirmation: false,
      configIndex: null
    }
  },

  methods: {
    addNewSection() {
      const newProp = {};

      for (const prop of this.properties) {
        newProp[prop.key] = this.generateDefaultByType(prop.type)
      }

      this.propertyValues.push(newProp)
    },

    generateDefaultByType(type) {
      if ([FIELD_TYPE_INPUT, FIELD_TYPE_TEXT_AREA, FIELD_TYPE_INPUT_NUMBER].includes(type)) return null
      if ([FIELD_TYPE_CHECKBOX, FIELD_TYPE_SWITCH].includes(type)) return false
      if (type === FIELD_TYPE_DATE_PICKER) return '@0'
    },

    confirmRemoval(index) {
      this.showRemoveConfirmation = true
      this.configIndex = index
    },

    closeRemoveConfirmation() {
      this.showRemoveConfirmation = false
      this.configIndex = null
    },

    deleteConfig() {
      this.propertyValues.splice(this.configIndex, 1)
      this.closeRemoveConfirmation()
    },

    configChange() {
      this.$emit('update:model-value', this.propertyValues)
    },

    handlePropModelChange(value) {
      if (!value) return this.propertyValues = []

      let index = 0

      for (const datum of value) {
        this.propertyValues[index] = datum

        index++
      }
    }
  },

  mounted() {
    this.handlePropModelChange(this.modelValue)
  }
}
</script>

<template>
  <q-card class="q-pa-sm q-mt-md">
    <q-card-section>
      <div class="text-grey-8">
        <span class="text-weight-bolder">
          {{ label }}

          <q-btn
            dense
            size="sm"
            color="primary"
            icon="add"
            label="Add Mapped Values"
            class="q-ml-sm"
            @click="addNewSection"
          />
        </span>

        <div v-if="$attrs.description" class="text-caption">
          <sq-markdown-display :markdown="$attrs.description" />
        </div>
      </div>
    </q-card-section>

    <q-card-section>
      <div class="q-mt-md">
        <q-card
          v-for="(propValue, index) in propertyValues"
          :key="index"
          class="q-mb-md"
        >
          <q-expansion-item
            default-opened
            dense
            dense-toggle
            switch-toggle-side
            header-class="expansion-header"
          >
            <div
              v-for="prop in properties"
              :key="`${index}_${prop.key}`"
              class="q-mb-sm q-pa-md"
            >
              <div>
                <span class="text-bold">{{ prop.key }}</span>
                Mapped Value:
              </div>
              <div>
                <single-field-config-by-type
                  v-model="propValue[prop.key]"
                  :type="prop.type"
                  @update:model-value="configChange"
                />
              </div>
            </div>

            <q-btn
              dense
              size="sm"
              label="Delete"
              color="negative"
              icon="delete"
              class="q-ml-sm"
              @click="confirmRemoval(index)"
            />
          </q-expansion-item>
        </q-card>
      </div>
    </q-card-section>

    <confirmation-dialog
      v-model="showRemoveConfirmation"
      title='Delete Confirmation'
      type="negative"
      @confirm="deleteConfig"
      @close="showRemoveConfirmation = false"
    >
      <template #content>
        <span>
          Are you sure you want to remove this field configuration? Once saved, you will never be able to revert the changes.
        </span>
      </template>
    </confirmation-dialog>
  </q-card>
</template>
