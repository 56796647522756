<script>
  import { mapActions } from 'vuex'
  import { debounce } from 'lodash'
  import {
    DATA_TYPES,
    DATA_TYPE_STR,
    DATA_TYPE_INT,
    DATA_TYPE_BOOL,
    DATA_TYPE_FLT,
    CONFIG_FIELDS,
    FIELD_TYPE_OPTIONS,
    FIELD_TYPE_OBJECT_ARRAY,
    FIELD_DEFAULT,
    FIELD_DEFAULT_OPTIONS,
    FIELD_DESCRIPTION,
    FIELD_VALIDATIONS,
    FIELD_DEFAULT_PROPERTIES,
    FIELD_TYPE_PROPERTY_MAPPING,
    FIELD_OBJECT_PROPERTY,
    FIELD_TYPE_SELECT,
    FIELD_TYPE_INPUT,
    FIELD_SEARCH_KEYS,
    FIELD_LOCALIZATION
  } from 'pages/JsonToForm/utils'
  import { noSpace, required } from 'pages/JsonToForm/Helpers/validations'
  import useLocalize from 'src/composables/useLocalize'

  import ConfirmationDialog from 'components/Functional/ConfirmationDialog'
  import FieldTypeSelector from 'pages/JsonToForm/Fields/FieldTypeSelector'
  import TabLabelGenerator from 'pages/JsonToForm/Fields/TabLabelGenerator'
  import DefaultValueEvaluator from 'pages/JsonToForm/Fields/DefaultValueEvaluator'
  import ValidationGenerator from 'pages/JsonToForm/Fields/ValidationGenerator'
  import DefaultPropertyGenerator from 'pages/JsonToForm/Fields/DefaultPropertyGenerator'
  import ObjectPropertyGenerator from 'pages/JsonToForm/Fields/ObjectPropertyGenerator.vue'
  import SqCheckbox from 'pages/JsonToForm/Fields/SqCheckbox.vue'
  import SqSelectWithAdd from 'pages/JsonToForm/Fields/SqSelectWithAdd.vue'
  import LocalizationGenerator from 'pages/JsonToForm/Fields/LocalizationGenerator.vue'

  export default {
    name: 'FieldConfigGenerator',

    components: {
      LocalizationGenerator,
      SqSelectWithAdd,
      SqCheckbox,
      ObjectPropertyGenerator,
      FieldTypeSelector,
      TabLabelGenerator,
      ConfirmationDialog,
      ValidationGenerator,
      DefaultValueEvaluator,
      DefaultPropertyGenerator
    },

    props: {
      modelValue: {
        type: Object
      },

      parent: {
        type: Object,
        required: false,
        default: null
      },

      id: {
        type: String,
        required: true
      },

      disable: {
        type: Boolean,
        required: false,
        default: false
      },

      removable: {
        type: Boolean,
        required: false,
        default: true
      },

      parentTabLabels: {
        required: false
      },

      showAdditionalOptions: {
        type: Boolean,
        required: false,
        default: true
      }
    },

    emits: ['update:model-value', 'delete', 'copy'],

    data() {
      return {
        showConfirmation: false,
        hasValidationError: false,
        form: {
          keyName: null,
          label: null,
          dataType: null,
          default: null,
          fieldType: null,
          description: null,
          isFieldConfig: true,
          isSelectMultiple: false,
          isSelectCanAdd: false,
          shouldMask: false,
          tabLabels: this.parentTabLabels || [],
          defaultOptions: [],
          validations: {},
          selectedFields: [],
          defaultProperties: [],
          isValid: false,
          objectProperties: [],
          searchKeys: [],
          localization: {}
        },

        FIELD_DEFAULT,
        FIELD_VALIDATIONS,
        FIELD_DESCRIPTION,
        FIELD_DEFAULT_OPTIONS,
        FIELD_DEFAULT_PROPERTIES,
        FIELD_TYPE_SELECT,
        FIELD_TYPE_INPUT,
        DATA_TYPE_STR
      }
    },

    computed: {
      localizedConfigFields() {
        return useLocalize(this.$t, CONFIG_FIELDS)
      },

      selectableFieldOptions() {
        return this.localizedConfigFields.filter(field => !this.form.selectedFields.includes(field.value))
      },

      canHaveDefault() {
        return [
          DATA_TYPE_STR,
          DATA_TYPE_INT,
          DATA_TYPE_FLT,
          DATA_TYPE_BOOL
        ].includes(this.form.dataType)
      },

      selectedFieldType() {
        return FIELD_TYPE_OPTIONS.find(field => field.value === this.form.fieldType)
      },

      hasDefaultField() {
        return this.isFieldPresent(FIELD_DEFAULT)
      },

      hasDefaultOptions() {
        return this.isFieldPresent(FIELD_DEFAULT_OPTIONS)
      },

      hasDescription() {
        return this.isFieldPresent(FIELD_DESCRIPTION)
      },

      hasSearchKeys() {
        return this.isFieldPresent(FIELD_SEARCH_KEYS)
      },

      hasDefaultProperties() {
        return this.isFieldPresent(FIELD_DEFAULT_PROPERTIES)
      },

      hasValidations() {
        return this.isFieldPresent(FIELD_VALIDATIONS)
      },

      hasObjectProperties() {
        return this.isFieldPresent(FIELD_OBJECT_PROPERTY)
      },

      hasLocalization() {
        return this.isFieldPresent(FIELD_LOCALIZATION)
      },

      isFormValid() {
        return !this.hasValidationError && !!(this.form.keyName && this.form.label && this.form.dataType && this.form.fieldType && this.form.tabLabels?.length)
      },

      availableFieldTypes() {
        if (this.form.dataType) {
          return FIELD_TYPE_OPTIONS.filter(fto => fto.forTypes.includes(this.form.dataType))
            .map(fto => fto.value)
        }

        return null
      },

      selectableDataTypes() {
        if (this.selectedFieldType) {
          return useLocalize(this.$t, DATA_TYPES.filter(dt => this.selectedFieldType.forTypes.includes(dt.value)))
        }

        return useLocalize(this.$t, DATA_TYPES)
      }
    },

    methods: {
      noSpace: noSpace(),
      required,

      ...mapActions('formBuilder', ['checkToRemoveTabLabel']),

      initialize() {
        if (this.modelValue?.keyName) {
          this.form.keyName = this.modelValue.keyName
          this.form.label = this.modelValue.label
          this.form.dataType = this.modelValue.dataType
          this.form.default = this.modelValue.default
          this.form.fieldType = this.modelValue.fieldType
          this.form.tabLabels = this.modelValue.tabLabels
          this.form.description = this.modelValue.description
          this.form.isFieldConfig = this.modelValue.isFieldConfig
          this.form.defaultOptions = this.modelValue.defaultOptions
          this.form.selectedFields = this.modelValue.selectedFields
          this.form.validations = this.modelValue.validations
          this.form.defaultProperties = this.modelValue.defaultProperties || []
          this.form.isValid = !!this.modelValue.isValid;
          this.form.objectProperties = this.modelValue.objectProperties
          this.form.isSelectMultiple = this.modelValue.isSelectMultiple
          this.form.isSelectCanAdd = this.modelValue.isSelectCanAdd
          this.form.shouldMask = this.modelValue.shouldMask
          this.form.searchKeys = this.modelValue.searchKeys || []
          this.form.localization = this.modelValue.localization || {}

          return
        }

        this.form.selectedFields = this.localizedConfigFields.filter(field => field.isDefault)
          .reduce((acc, field) => [...acc, ...[field.value]], [])

        this.configChange()
      },

      configChange: debounce(async function() {
        const isKeyNameValid = await this.$refs.keyNameRef?.validate()
        this.form.isValid = this.isFormValid && isKeyNameValid
        this.hasValidationError = !isKeyNameValid

        this.$emit('update:model-value', this.form)
      }, 100),

      handleDataTypeChange() {
        this.form.default = null

        if (this.form.dataType !== DATA_TYPE_BOOL) {
          this.form.isSelectCanAdd = false
          this.form.isSelectMultiple = false
        }

        this.configChange()
      },

      handleFieldTypeChange(type) {
        this.form.defaultOptions = []
        this.form.validations = {}
        this.form.defaultOptions = []
        this.form.isSelectCanAdd = false
        this.form.isSelectMultiple = false
        this.form.shouldMask = false

        const fieldType = FIELD_TYPE_OPTIONS.find(field => field.value === type)

        if (!fieldType.forTypes.includes(this.form.dataType)) {
          this.form.dataType = null
        }

        if (fieldType) {
          this.form.default = fieldType.default
        }

        if (fieldType?.value !== FIELD_TYPE_PROPERTY_MAPPING) {
          this.removeField(FIELD_DEFAULT_PROPERTIES, FIELD_DEFAULT_PROPERTIES, [])
        }

        if (fieldType?.value !== FIELD_TYPE_OBJECT_ARRAY) {
          this.removeField(FIELD_OBJECT_PROPERTY, FIELD_OBJECT_PROPERTY, [])
        }

        if (!fieldType?.validators?.length) {
          this.removeField(FIELD_VALIDATIONS, FIELD_VALIDATIONS, {})
        }

        this.configChange()
      },

      onItemClick(value) {
        if (!this.isFieldPresent(value)) {
          this.form.selectedFields.push(value)

          this.configChange()
        }
      },

      isDisable(option) {
        if (option.value === FIELD_DEFAULT && !this.canHaveDefault) return true
        else if (option.value === FIELD_DEFAULT_PROPERTIES && this.form.fieldType !== FIELD_TYPE_PROPERTY_MAPPING) return true
        else if (option.value === FIELD_OBJECT_PROPERTY && this.form.fieldType !== FIELD_TYPE_OBJECT_ARRAY) return true
        else if (option.value === FIELD_VALIDATIONS && !this.selectedFieldType?.validators?.length) return true
        return option.value === FIELD_DEFAULT_OPTIONS && this.selectedFieldType?.value !== FIELD_TYPE_SELECT;
      },

      isFieldPresent(key) {
        return this.form.selectedFields.includes(key)
      },

      removeField(field, model, revertValue = null) {
        const index = this.form.selectedFields.indexOf(field)

        if (index > -1) {
          this.form.selectedFields.splice(index, 1)

          this.form[model] = revertValue

          this.configChange()
        }
      },

      deleteField() {
        if (this.form.tabLabels?.length) {
          this.form.tabLabels.forEach(label => this.checkToRemoveTabLabel(label))
        }

        this.$emit('delete')
      },

      handleIsSelectMultipleChange() {
        this.form.default = null
        this.configChange()
      },

      handleCopyNode() {
        this.$emit('copy')
      }
    },

    mounted() {
      this.initialize()
    }
  }
</script>

<template>
  <q-card class="q-my-md q-pb-sm">
    <q-expansion-item
      default-opened
      dense
      dense-toggle
      switch-toggle-side
      header-class="expansion-header"
    >
      <template #header>
        <div class="flex items-center full-width">
          <div
            class="text-weight-bolder"
            :class="{
              'text-red': !isFormValid,
              'text-grey-8': isFormValid
            }"
          >
            {{ `${form.label || ''}` }} {{ $t('jsonToForm.formBuilder.form.fieldConfig') }}
          </div>

          <div
            v-if="!isFormValid"
            class="q-ml-md text-red"
          >
            {{ $t('jsonToForm.formBuilder.validation.invalidFieldConfig') }}
          </div>

          <div class="q-ml-auto">
            <q-btn
              dense
              flat
              icon="content_copy"
              @click.stop="handleCopyNode"
            />
          </div>
        </div>
      </template>

      <div class="q-px-md">
        <div class="q-pt-md">
          <div class="row q-col-gutter-md">
            <div class="col-12 col-md-6 col-lg-6">
              <q-input
                ref="keyNameRef"
                v-model="form.keyName"
                clearable
                dense
                filled
                :disable="disable"
                :label="`* ${$t('jsonToForm.formBuilder.form.keyName') }`"
                :rules="[required($t('jsonToForm.formBuilder.validation.isRequired', { field: $t('jsonToForm.formBuilder.form.keyName') })), noSpace]"
                class="q-py-sm"
                @blur="configChange"
              />
            </div>
            <div class="col-12 col-md-6 col-lg-6">
              <q-input
                v-model="form.label"
                clearable
                dense
                filled
                :disable="disable"
                :label="`* ${$t('jsonToForm.formBuilder.form.fieldLabel') }`"
                :rules="[required($t('jsonToForm.formBuilder.validation.isRequired', { field: $t('jsonToForm.formBuilder.form.fieldLabel') }))]"
                class="q-py-sm"
                @blur="configChange"
              />
            </div>
          </div>

          <div class="row q-col-gutter-md">
            <div class="col-12 col-md-6 col-lg-6">
              <q-select
                v-model="form.dataType"
                clearable
                dense
                options-dense
                filled
                emit-value
                map-options
                :disable="disable"
                :label="`* ${$t('jsonToForm.formBuilder.form.valueType') }`"
                :options="selectableDataTypes"
                :rules="[required($t('jsonToForm.formBuilder.validation.isRequired', { field: $t('jsonToForm.formBuilder.form.valueType') }))]"
                class="q-py-sm"
                @update:model-value="handleDataTypeChange"
              />
            </div>

            <div class="col-12 col-md-6 col-lg-6">
              <field-type-selector
                v-model="form.fieldType"
                :disable="disable"
                :only="availableFieldTypes"
                :rules="[required($t('jsonToForm.formBuilder.validation.isRequired', { field: $t('jsonToForm.formBuilder.form.fieldType') }))]"
                @update:model-value="handleFieldTypeChange"
              />
            </div>
          </div>

          <div v-if="form.fieldType === FIELD_TYPE_INPUT">
            <div class="col-12 col-md-6 col-lg-6">
              <sq-checkbox
                v-model="form.shouldMask"
                label="Should Mask"
                @update:model-value="configChange"
              />
            </div>
          </div>

          <div
            v-if="form.fieldType === FIELD_TYPE_SELECT"
            class="row q-col-gutter-md"
          >
            <div class="col-12 col-md-6 col-lg-6">
              <sq-checkbox
                v-model="form.isSelectMultiple"
                label="Is Multiple"
                :disable="form.dataType === DATA_TYPE_STR"
                style="width: 200px;"
                @update:model-value="handleIsSelectMultipleChange"
              >
                <template #tooltip>
                  <q-tooltip v-if="form.dataType === DATA_TYPE_STR">
                    Not applicable for single value select
                  </q-tooltip>
                </template>
              </sq-checkbox>
            </div>
            <div class="col-12 col-md-6 col-lg-6">
              <sq-checkbox
                v-model="form.isSelectCanAdd"
                label="Can Add New Value"
                :disable="form.dataType === DATA_TYPE_STR"
                style="width: 200px;"
                @update:model-value="configChange"
              >
                <template #tooltip>
                  <q-tooltip v-if="form.dataType === DATA_TYPE_STR">
                    Not applicable for single value select
                  </q-tooltip>
                </template>
              </sq-checkbox>
            </div>
          </div>

          <tab-label-generator
            v-model="form.tabLabels"
            :parent-tab-labels="parentTabLabels"
            :disable="disable"
            @has-error="form.isValid"
            @update:model-value="configChange"
          />

          <sq-select-with-add
            v-if="hasSearchKeys"
            v-model="form.searchKeys"
            use-chips
            multiple
            label="Search Keys"
            @update:model-value="configChange"
          />

          <q-input
            v-if="hasDescription"
            v-model="form.description"
            clearable
            dense
            filled
            type="textarea"
            label="Description"
            class="q-pb-md"
            @blur="configChange"
          >
            <template #after>
              <q-btn
                dense
                flat
                color="negative"
                icon="delete"
                @click="removeField(FIELD_DESCRIPTION, 'description')"
              />
            </template>
          </q-input>

          <q-select
            v-if="hasDefaultOptions"
            v-model="form.defaultOptions"
            clearable
            dense
            options-dense
            filled
            emit-value
            map-options
            use-input
            multiple
            use-chips
            new-value-mode="add-unique"
            label="Options"
            class="q-pb-md"
            @update:model-value="configChange"
          >
            <template #after>
              <q-btn
                color="negative"
                icon="delete"
                @click="removeField(FIELD_DEFAULT_OPTIONS, 'defaultOptions', [])"
              />
            </template>
          </q-select>

          <default-value-evaluator
            v-if="hasDefaultField"
            v-model="form.default"
            :field-config="form"
            :field-type="form.fieldType"
            :select-options="form.defaultOptions"
            @delete="removeField(FIELD_DEFAULT, 'default')"
            @update:model-value="configChange"
          />

          <default-property-generator
            v-if="hasDefaultProperties"
            v-model="form.defaultProperties"
            @delete="removeField(FIELD_DEFAULT_PROPERTIES, 'defaultProperties', [])"
            @update:model-value="configChange"
          />

          <validation-generator
            v-if="hasValidations"
            v-model="form.validations"
            :field-type="form.fieldType"
            @delete="removeField(FIELD_VALIDATIONS, 'validations', {})"
            @update:model-value="configChange"
          />

          <object-property-generator
            v-if="hasObjectProperties"
            v-model="form.objectProperties"
            @update:model-value="configChange"
          />

          <localization-generator
            v-if="hasLocalization"
            v-model="form.localization"
            @update:model-value="configChange"
          />
        </div>

        <div class="flex q-pb-md q-mt-md">
          <q-btn-dropdown
            v-if="showAdditionalOptions"
            dense
            split
            auto-close
            disable-main-btn
            size="sm"
            class="glossy"
            color="primary"
            label="Select Additional Option"
          >
            <q-list>
              <q-item
                v-for="option in selectableFieldOptions"
                :key="option.value"
                clickable
                dense
                :disable="isDisable(option)"
                @click="onItemClick(option.value)">
                <q-item-section>
                  {{ option.label }}
                </q-item-section>
              </q-item>
            </q-list>
          </q-btn-dropdown>

          <q-btn
            v-if="removable"
            dense
            size="sm"
            :label="$t('common.delete')"
            color="negative"
            icon="delete"
            class="q-ml-sm"
            @click="showConfirmation = true"
          />
        </div>
      </div>
    </q-expansion-item>

    <confirmation-dialog
      v-model="showConfirmation"
      title='Delete Confirmation'
      @confirm="deleteField"
      @close="showConfirmation = false"
    >
      <template #content>
        <span>
          Are you sure you want to remove this field configuration? Once saved, you will never be able to revert the changes.
        </span>
      </template>
    </confirmation-dialog>
  </q-card>
</template>

<style lang="scss">
  .q-field--dense .q-field__bottom {
    margin-bottom: 5px !important;
  }

  .expansion-header {
    padding: 0;

    .q-item__section--avatar {
      min-width: 1.5rem;
    }
  }
</style>
