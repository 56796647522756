<script>
import { v4 as uuid } from 'uuid'
import { debounce } from 'lodash'
import ConfirmationDialog from 'components/Functional/ConfirmationDialog.vue'
import SqMarkdownDisplay from 'components/Common/SqMarkdownDisplay.vue'

export default {
  name: 'SqPropertyMapping',
  components: { SqMarkdownDisplay, ConfirmationDialog },

  props: {
    modelValue: {
      type: Object,
      required: false
    },

    label: {
      type: String,
      required: true
    },

    defaultProperties: {
      type: Array,
      required: false
    }
  },

  emits: ['update:model-value'],

  data() {
    return {
      showDeleteConfirmation: false,
      selectedParent: null,
      selectedField: null,
      toDeleteType: null,
      value: this.modelValue,
      dataObject: {},
      form: []
    }
  },

  computed: {
    deleteConfirmationMessage() {
      return this.toDeleteType === 'field' ? 'Are you sure you want to delete this field?' : 'Are you sure you want to delete this config?'
    }
  },

  methods: {
    initializeValues(values) {
      let index = 0
      for (const config in values) {
        const configValues = values[config]
        const formConfig = this.form[index]

        if (formConfig) {
          formConfig.value = config
          this.processValues(formConfig, configValues)
        } else {
          this.addNewObject(config)
          this.processValues(this.form.at(-1), configValues)
        }

        index++
      }
    },

    processValues(item, values) {
      if (!values) return

      const keys = Object.keys(values)
      keys?.forEach((value, index) => {
        if (item.children[index]) {
          item.children[index].value = values[value]
          item.children[index].key = value
        } else {
          this.addNewProperty(item, value, values[value])
        }
      })

      item.children.splice(keys?.length)
    },

    initialize() {
      if (this.defaultProperties.length) {
        this.defaultProperties.forEach(prop => {
          if (!this.modelValue[prop]) {
            this.addNewObject(prop)
          }
        })

        this.handleObjectChange()
      }
    },

    addNewObject(value = null) {
      this.form.push({
        id: uuid(),
        value,
        children: []
      })
      this.dataObject[value] = {}
    },

    addNewProperty(ui, key, value) {
      ui.children.push({
        id: uuid(),
        value,
        key,
        parent: ui.id,
        type: 'string'
      })
    },

    handleConfirmDelete(item, field) {
      this.selectedParent = item
      this.selectedField = field
      this.showDeleteConfirmation = true
      this.toDeleteType = field ? 'field' : 'parent'
    },

    closeDeleteConfirmation() {
      this.selectedField = null
      this.selectedParent = null
      this.toDeleteType = null
      this.showDeleteConfirmation = false
    },

    deleteConfig() {
      const parentIndex = this.form.findIndex(item => item.id === this.selectedParent.id)
      const fieldIndex = this.form[parentIndex]?.children?.findIndex(item => item.id === this.selectedField?.id)

      if (fieldIndex > -1) {
        this.form[parentIndex].children.splice(fieldIndex, 1)
      } else if (parentIndex > -1) {
        this.form.splice(parentIndex, 1)
      }

      this.handleObjectChange()
      this.closeDeleteConfirmation()
    },

    handleObjectChange: debounce(function () {
      const object = this.buildObject();

      this.$emit('update:model-value', object)
    }, 500),

    buildObject() {
      const object = {}

      this.form.forEach(item => {
        object[item.value] = {}

        item.children.forEach(child => {
          if (child.key) {
            object[item.value][child.key] = child.value
          }
        })
      })

      return object
    }
  },

  mounted() {
    this.modelValue ? this.initializeValues(this.modelValue) : this.initialize()
  }
}
</script>

<template>
  <div>
    <q-card class="q-my-md">
      <q-expansion-item
        default-opened
        dense
        dense-toggle
        switch-toggle-side
        header-class="expansion-header"
      >
        <template #header>
          <div class="flex items-center full-width">
            <div class="text-weight-bolder">
              {{ label }}

              <q-btn
                dense
                size="sm"
                label="Add New Object"
                color="primary"
                icon="add"
                class="q-ml-sm"
                @click.stop="addNewObject(null)"
              />
            </div>
          </div>
        </template>

        <div v-if="$attrs.description" class="text-caption">
          <sq-markdown-display :markdown="$attrs.description" />
        </div>

        <q-card-section class="q-pa-sm">
          <q-expansion-item
            v-if="form.length"
            v-for="item in form"
            :key="item.id"
            default-opened
            dense
            dense-toggle
            switch-toggle-side
            header-class="expansion-header"
            class="q-mt-md"
          >
            <template #header>
              <div class="flex items-center full-width">
                <div class="text-weight-bolder">
                  Object Config

                  <q-btn
                    dense
                    size="sm"
                    color="primary"
                    icon="add"
                    label="Add Property"
                    class="glossy q-ml-md"
                    @click.stop="addNewProperty(item)"
                  />

                  <q-btn
                    dense
                    round
                    flat
                    size="sm"
                    color="negative"
                    icon="delete"
                    class="q-ml-sm"
                    @click.stop="handleConfirmDelete(item)"
                  />
                </div>
              </div>
            </template>

            <q-card-section class="q-ma-sm">
              <sq-input-field
                v-model="item.value"
                class="q-py-sm"
                @update:model-value="handleObjectChange"
              />

              <q-card
                v-for="child in item.children"
                :key="child.id"
                bordered
                class="q-ml-lg q-mt-md"
              >
                <q-expansion-item
                  default-opened
                  dense
                  dense-toggle
                  switch-toggle-side
                  header-class="expansion-header"
                >
                  <template #header>
                    <div class="flex items-center full-width">
                      <div class="text-weight-bolder">
                        {{ child.key }} Property Config

                        <q-btn
                          dense
                          round
                          flat
                          size="sm"
                          color="negative"
                          icon="delete"
                          class="q-ml-sm"
                          @click.stop="handleConfirmDelete(item, child)"
                        />
                      </div>
                    </div>
                  </template>

                  <q-card-section class="row q-col-gutter-md">
                    <div class="col-6">
                      <sq-input-field
                        v-model="child.key"
                        label="Property Key"
                        @update:model-value="handleObjectChange"
                      />
                    </div>
                    <div class="col-6">
                      <sq-input-field
                        v-if="child.type === 'string'"
                        v-model="child.value"
                        label="Property Value"
                        @update:model-value="handleObjectChange"
                      />
                    </div>
                  </q-card-section>
                </q-expansion-item>
              </q-card>
            </q-card-section>

            <q-separator />
          </q-expansion-item>
        </q-card-section>
      </q-expansion-item>
    </q-card>

    <confirmation-dialog
      v-model="showDeleteConfirmation"
      :title="toDeleteType === 'parent' ? 'Delete Config' : 'Delete Field'"
      type="negative"
      @confirm="deleteConfig"
      @close="closeDeleteConfirmation"
    >
      <template #content>
        <span>
          {{ deleteConfirmationMessage }}
        </span>
      </template>
    </confirmation-dialog>
  </div>
</template>
