<script>
import FormBuilder from 'pages/JsonToForm/FormBuilder.vue'
import { v4 as uuid } from 'uuid'
import { mapActions } from 'vuex'
import { notifySuccess } from 'src/utils/notify'

export default {
  name: 'FormBuilderForm',

  components: { FormBuilder },

  props: {
    id: {
      type: String,
      required: false
    }
  },

  data() {
    return {
      selectedConfig: null,
      configList: []
    }
  },

  methods: {
    ...mapActions('formBuilder', ['reset']),

    saveConfig({ builder, close }) {
      builder.id = this.selectedConfig ? this.selectedConfig.id : uuid()

      if (this.selectedConfig) {
        const index = this.configList.findIndex(item => item.id === this.selectedConfig.id)

        if (index > -1) {
          this.configList.splice(index, 1, builder)
        }
      } else {
        this.configList.push(builder)
      }

      localStorage.setItem('configs', JSON.stringify(this.configList))

      this.reset()

      notifySuccess(this.$t(`jsonToForm.formBuilder.notification.${this.id ? 'formUpdated' : 'formCreated'}`))

      if (close) this.closeForm()
      else if (!this.id) this.$router.push({ name: 'UpdateForm', params: { id: builder.id } })
    },

    closeForm() {
      this.reset()
      this.$router.push({ name: 'FormBuilder' })
    }
  },

  created() {
    const data = localStorage.getItem('configs')

    if (data) {
      this.configList = JSON.parse(data)
      this.selectedConfig = this.configList.find(conf => conf.id === this.id)
    }
  }
}
</script>

<template>
  <div class="form-builder-form q-pa-md">
    <q-breadcrumbs class="custom-breadcrumb q-mb-md">
      <q-breadcrumbs-el
        icon="list_alt"
        :label="$t('jsonToForm.formBuilder.formConfigList')"
        :to="{ name: 'FormBuilder' }"
      />
      <q-breadcrumbs-el
        :label="$t('jsonToForm.formBuilder.formBuilder')"
        icon="construction"
      />
    </q-breadcrumbs>

    <form-builder
      ref="objectBuilder"
      :model-value="selectedConfig"
      @save="saveConfig"
      @close-builder="closeForm"
    />
  </div>
</template>

<style lang="scss">
  .form-builder-form {
    min-height: calc(100vh - 100px);
  }
</style>
