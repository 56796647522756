/**
 * Capitalized all first letter of every word in a string
 *
 * @param str <string>
 * @return <string>
 */
export function ucwords (str) {
  if (!str) return '';

  if (!(typeof str === 'string')) return '';

  return (`${str.toLowerCase()}`).
    replace(/^(.)|\s+(.)/g, (char) => {
      return char.toUpperCase();
    });
}
