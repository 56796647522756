<script>
  import JsonEditor from 'components/JsonEditor.vue'

  export default {
    name: 'ConfigView',

    components: { JsonEditor },

    props: {
      jsonConfig: {
        type: String,
        required: false,
        default: () => ''
      }
    }
  }
</script>

<template>
  <q-card bordered>
    <q-card-section>
      <b>JSON</b>
      <json-editor
        :model-value="jsonConfig"
        max-height="none"
        disabled
        readonly
        :show-toolbar="false"
        style="height: 100vh"
      />
    </q-card-section>
  </q-card>
</template>
