<script>
import { flowService } from 'src/services'
import { notifyError, notifySuccess } from 'src/utils/notify'
import { debounce, isEmpty } from 'lodash'
import ConfirmationDialog from 'components/Functional/ConfirmationDialog.vue'
import ConfigForm from 'pages/JsonToForm/ConfigForm.vue'
import JsonEditor from 'components/JsonEditor.vue'

export default {
  name: 'JdmConfigBuilder',

  components: {
    ConfirmationDialog,
    JsonEditor,
    ConfigForm
  },

  props: {
    id: {
      type: String,
      required: true
    },

    jdmId: {
      type: [String, Number],
      required: true
    }
  },

  data() {
    return {
      hasChanges: false,
      showLeaveConfirmation: false,
      toRoute: null,
      isLoading: true,
      viewMode: 'mode_full',
      expertMode: false,
      currentTab: 'config',
      isJdmConfigValid: true,
      formSchema: {},
      flow: null,
      jdm: null,
      moduleConfig: "",
      dispatchCondition: "",
      showSwitchConfirmation: false
    }
  },

  computed: {
    hasUiSchema() {
      return !!this.formSchema?.properties
    }
  },

  methods: {
    async initialize() {
      this.isLoading = true

      await this.getFlow()


      this.isLoading = false
    },

    async getFlow() {
      try {
        await flowService.getFlowDetails(this.id, async (data) => {
          this.flow = data

          this.jdm = data.jobDispatcherMappings.find((jdm) => Number(jdm.id) === Number(this.jdmId))

          if (this.jdm) {
            await this.getUiSchema()
            this.assignInitialConfigValues()
          }
        })
      } catch (error) {
        console.error(error)

        notifyError(this.$t('jsonToForm.jdmScreen.notification.failedToLoadFlow'))
      }
    },

    async getUiSchema() {
      try {
        const response = await this.$api.formBuilder.getModuleSchema(this.jdmId)

        if (response.data.data) {
          this.formSchema = JSON.parse(response.data.data)
        }
      } catch (error) {
        console.error(error)

        notifyError(this.$t('jsonToForm.jdmScreen.notification.failedToLoadSchema'))
      }
    },

    async handleSaveJdm() {
      if (this.hasUiSchema && !this.expertMode) {
        const isFormValid = await this.$refs.configFormRef.$refs.jsonToFormRef.validate()

        if (!isFormValid) {
          return notifyError(this.$t('jsonToForm.jdmScreen.notification.invalidForm'))
        }
      }

      try {
        this.isLoading = true

        const postData = {
          configuration: this.hasUiSchema ? this.moduleConfig : JSON.parse(this.moduleConfig),
          dispatchCondition: JSON.parse(this.dispatchCondition)
        }

        await flowService.patchJobDispatcherMapping(this.jdm.id, postData)

        notifySuccess(this.$t('jsonToForm.jdmScreen.notification.jdmSaved'))
        this.hasChanges = false
      } catch (error) {
        console.error('Something went wrong ' . error)

        notifyError(this.$t('jsonToForm.jdmScreen.notification.jdmSaveFailed'))
      } finally {
        this.isLoading = false
      }
    },

    assignInitialConfigValues() {
      this.moduleConfig = this.hasUiSchema ? isEmpty(this.jdm?.configuration) ? null : this.jdm.configuration : JSON.stringify(this.jdm.configuration, null, 2)
      this.dispatchCondition = JSON.stringify(this.jdm?.dispatchCondition, null, 2)
    },

    handleValidJson: debounce(function(valid) {
      this.isJdmConfigValid = valid
    }, 300),

    confirmSwitch() {
      this.expertMode = false
      this.isJdmConfigValid = true
    },

    cancelSwitch(isCancel) {
      if (isCancel) this.viewMode = 'mode_expert'
      this.showSwitchConfirmation = false
    },

    handleViewModeChange() {
      if (this.expertMode && !this.isJdmConfigValid) {
        this.showSwitchConfirmation = true
        return
      }

      this.expertMode = this.viewMode === 'mode_expert'
    },

    handleHasChange() {
      this.hasChanges = true
    },

    async confirmLeave() {
      this.hasChanges = false
      await this.$router.push({ path: this.toRoute.path })
    },

    closeLeaveConfirmation() {
      this.showLeaveConfirmation = false
      this.toRoute = null
    },

    preventScreenReload(event) {
      if (!this.hasChanges) return

      event.preventDefault()
      event.returnValue = ""
    }
  },

  created() {
    this.initialize()
  },

  beforeRouteLeave(to) {
    if (this.hasChanges) {
      this.showLeaveConfirmation = true
      this.toRoute = to
      return false
    }

    return true
  },

  beforeMount() {
    window.addEventListener("beforeunload", this.preventScreenReload)
  },

  beforeUnmount() {
    window.removeEventListener("beforeunload", this.preventScreenReload)
  }
}

</script>

<template>
  <div class="q-pa-sm">
    <q-card class="q-px-sm">
      <q-card-section class="flex items-center">
        <div class="flex">
          <q-breadcrumbs class="custom-breadcrumb">
            <q-breadcrumbs-el
              icon="img:assets/icons/menu/flows.svg"
              :label="$t('menu.routes.flows')"
              to="/flow"
            />
            <q-breadcrumbs-el
              icon="settings"
              :label="$t('jsonToForm.jdmScreen.flowConfig')"
              :to="`/flow/${id}`"
            />
            <q-breadcrumbs-el
              icon="web"
              :label="$t('jsonToForm.jdmScreen.jdmForm')"
            />
          </q-breadcrumbs>

          <div class="full-width">
            <h2 class="flow-name">{{ flow?.name }}</h2>
          </div>
        </div>

        <div class="q-ml-auto">
          <q-btn-toggle
            v-if="hasUiSchema"
            v-model="viewMode"
            no-caps
            dense
            toggle-color="primary"
            :options="[
              { value: 'mode_full', slot: 'full' },
              { value: 'mode_split', slot: 'split' },
              { value: 'mode_expert', slot: 'expert' }
            ]"
            class="q-mr-md"
            @update:model-value="handleViewModeChange"
          >
            <template v-slot:full>
              <div class="row items-center no-wrap">
                <q-icon name="fullscreen" class="q-mr-xs" />
                {{ $t('jsonToForm.jdmScreen.viewMode.fullView') }}
              </div>
            </template>

            <template v-slot:split>
              <div class="row items-center no-wrap">
                <q-icon name="vertical_split" class="q-mr-xs" />
                {{ $t('jsonToForm.jdmScreen.viewMode.splitView') }}
              </div>
            </template>

            <template v-slot:expert>
              <div class="row items-center no-wrap">
                <q-icon name="data_object" class="q-mr-xs" />
                {{ $t('jsonToForm.jdmScreen.viewMode.expertMode') }}
              </div>
            </template>
          </q-btn-toggle>

          <q-btn
            :disable="!isJdmConfigValid"
            color="primary"
            :label="$t('common.save')"
            icon="save"
            dense
            @click="handleSaveJdm"
            data-cy="J2FSaveButton"
          />
        </div>
      </q-card-section>
    </q-card>

    <div v-if="!isJdmConfigValid">
      <div class="text-red-5">
        {{ $t('jsonToForm.jdmScreen.notification.invalidConfigurationCheckConfig') }}
        <template v-if="hasUiSchema">
          <span>{{ $t('jsonToForm.jdmScreen.notification.invalidConfigurationCheckConfigCont1') }}</span>
          <div>
            <span class="text-bold">{{ $t('common.note') }}:</span> {{ $t('jsonToForm.jdmScreen.notification.invalidConfigurationCheckConfigCont2') }}
          </div>
        </template>
      </div>
    </div>

    <q-tabs
      v-model="currentTab"
      align="left"
      dense
      active-color="primary"
      indicator-color="primary"
      narrow-indicator
      class="q-mt-md"
    >
      <q-tab
        name="config"
        :label="$t('flow.overview.detail.modal.moduleConfig')"
        :ripple="false"
      />

      <q-tab
        name="condition"
        :label="$t('flow.overview.detail.modal.dispatchCondition')"
        :ripple="false"
      />
    </q-tabs>

    <q-tab-panels
      v-model="currentTab"
      animated
      keep-alive
      style="background: transparent"
    >
      <q-tab-panel name="config">
        <config-form
          ref="configFormRef"
          v-if="hasUiSchema"
          data-cy="jsonEditorFlowConfig"
          v-model="moduleConfig"
          :schema="formSchema"
          :expert-mode="expertMode"
          :show-object-viewer="false"
          show-search
          show-tab-quick-access
          show-view-mode-option
          :view-mode="viewMode"
          @save="handleSaveJdm"
          @is-valid-config="handleValidJson"
          @has-change="handleHasChange"
        />

        <json-editor
          v-else
          data-cy="jsonEditorFlowConfig"
          v-model="moduleConfig"
          :disabled="isLoading"
          max-height="none"
          @is-valid-json="handleValidJson"
        />
      </q-tab-panel>

      <q-tab-panel name="condition">
        <json-editor
          v-model="dispatchCondition"
          :disabled="isLoading"
          max-height="none"
          @is-valid-json="handleValidJson"
          @update:model-value="handleHasChange"
        />
      </q-tab-panel>
    </q-tab-panels>

    <q-inner-loading
      :showing="isLoading"
      color="primary"
      size="2em"
    />

    <confirmation-dialog
      v-model="showSwitchConfirmation"
      :title="$t('jsonToForm.jdmScreen.dialog.confirmSwitch')"
      type="negative"
      no-backdrop-dismiss
      @confirm="confirmSwitch"
      @close="cancelSwitch"
    >
      <template #content>
        <span>
          {{ $t('jsonToForm.jdmScreen.dialog.confirmSwitchMessage') }}
        </span>
      </template>
    </confirmation-dialog>

    <confirmation-dialog
      v-model="showLeaveConfirmation"
      :title="$t('jsonToForm.jdmScreen.dialog.confirmLeave')"
      type="warning"
      @confirm="confirmLeave"
      @close="closeLeaveConfirmation"
    >
      <template #content>
        <span>
          {{ $t('jsonToForm.jdmScreen.dialog.confirmLeaveMessage') }}
        </span>
      </template>
    </confirmation-dialog>
  </div>
</template>

<style lang="scss">
  .preference-popup {
    min-width: 250px;
  }

  .flow-name {
    line-height: 35px;
  }
</style>
