<script>
import VueApexCharts from 'vue3-apexcharts'
import CustomChartLegend from "components/Charts/Legend/CustomChartLegend";
import severityLevels from "src/meta/severityLevels";

export default {
  name: "FlowSeveritiesChart",

  components: {
    VueApexCharts,
    CustomChartLegend
  },
  data() {
    return {
      legendData: [],
      loading: false,
      series: [],
      options: {
        chart: {
          type: 'bar',
          stacked: true,
          toolbar: {
            show: false
          },
          foreColor: this.$q.dark.isActive ? '#FFFFFF' : '#373d3f'
        },
        plotOptions: {
          bar: {
            horizontal: true
          },
        },
        stroke: {
          width: 1,
          colors: ['#fff']
        },
        xaxis: {
          categories: [],
          labels: {
            formatter: function (val) {
              return val
            }
          }
        },
        yaxis: {
          title: {
            text: undefined
          }
        },
        tooltip: {
          y: {
            title: {
              formatter: (val) => this.$t('validation.severityLevelTypes.' + val) + ':'
            },
            formatter: function (val) {
              return val
            }
          }
        },
        fill: {
          opacity: 1
        },
        legend: {
          show: false
        },
        colors: [],
        responsive: [
          {
            breakpoint: 600,
            options: {
              chart: {
                horizontal: true,
                height: 350
              },
              plotOptions: {
                bar: {
                  horizontal: false
                }
              }
            }
          },
          {
            breakpoint: undefined,
            options: {
              chart: {
                horizontal: false
              },
              plotOptions: {
                bar: {
                  horizontal: true
                }
              }
            }
          }
        ]
      }
    }
  },
  created() {
    this.options.colors = this.severityColors;
    this.getFlowSeveritiesGroup()
  },
  computed: {
    severityColors() {
      let colors = [];
      for (const color of Object.values(severityLevels.apexChartStatusColors)) {
        colors.push(color)
      }
      // Correct alert color
      [colors[0], colors[1]] = [colors[1], colors[0]];
      // Correct warning color
      [colors[1], colors[2]] = [colors[2], colors[1]];
      return colors;
    }
  },
  methods: {
    getFlowSeveritiesGroup() {
      this.loading = true;
      this.series = []
      this.legendData = [];

      this.$api.validationInfo.getFlowSeveritiesGroup()
        .then(res => {
          if (res.data['hydra:member'].length > 0) {
            const flowSeveritiesGroupData = res.data['hydra:member']
            let seriesArray = [];

            flowSeveritiesGroupData.forEach(data => {
              for (const [key, value] of Object.entries(data)) {
                if (key !== 'flowName') {
                  (seriesArray[key] ??= []).push(value);
                }
              }

              this.options.xaxis.categories.push(data['flowName']);
            })

            let counter = 0;
            for (const [key, value] of Object.entries(seriesArray)) {
              this.series.push({name: key, data: value})
              this.legendData.push({
                id: key,
                color: this.options.colors[counter],
                description: this.$t('validation.severityLevelTypes.' + key),
                url: '#/validation/infos' + '?severity=' + encodeURIComponent(key)
              })
              counter++;
            }

            this.$refs?.flowChart?.updateSeries(this.series)
          }
        })
        .catch(err => {
          console.error(err)
        })
        .finally(_ => {
          this.loading = false
        })
    },

    clickHandler(event, chartContext, config) {
      const level = this.legendData[config.seriesIndex]?.id;
      if(level) {
        const query = { severity: encodeURIComponent(level) };
        this.$router.push({path: 'infos', query: query});
      }
    }
  }
}
</script>


<template>
  <div class="q-pa-md flow-severities-chart position-relative">

    <div class="column items-center">
      <p class="chart-header">{{ $t('validation.flowSeverities') }}</p>
    </div>

    <div class="row justify-center">
      <div class="col-12 col-xl-8 self-center">
        <vue-apex-charts
          v-if="!loading & legendData.length > 0"
          ref="flowChart"
          type="bar"
          :options="options"
          :series="series"
          class="validation-graph app-linkable-graph q-mb-lg"
          height="100%"
          @click="clickHandler"
        />
      </div>

      <div class="col-12 col-xl-2 self-center">
        <custom-chart-legend :legend-data="legendData" />
        <q-inner-loading
          :showing="loading"
          color="primary"
        >
          <q-spinner
            color="primary"
            size="42px"
          />
          <span class="text-primary text-weight-medium">{{ $t('validation.loadingData') }}</span>
        </q-inner-loading>
      </div>

    </div>

    <div class="absolute-center" v-if="legendData.length === 0 && !loading">
      <span class="text-primary text-weight-medium">{{ $t('validation.noData') }}</span>
    </div>
  </div>
</template>
