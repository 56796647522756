/**
 * Determine if the value is null or undefined
 *
 * @param value {any}
 *
 * @returns {boolean}
 */
export function isNullOrUndefined(value) {
  return value === null || isUndefined(value)
}

/**
 * Determine if the value is undefined
 *
 * @param value {any}
 *
 * @returns {boolean}
 */
export function isUndefined(value) {
  return value === undefined;
}
