<script>
import VueFormJsonSchema from '@synqup/synqup-test-two'
import { isNullOrUndefined, notNullOrUndefined } from 'src/utils'
import { cloneDeep, debounce, isEmpty, isEqual } from 'lodash'
import { v4 as uuid } from 'uuid'
import { marked } from 'marked'
import {
  FIELD_TYPE_CHECKBOX,
  FIELD_TYPE_DATE_PICKER,
  FIELD_TYPE_DYNAMIC_INPUT,
  FIELD_TYPE_DYNAMIC_OBJECT_MAPPING,
  FIELD_TYPE_INPUT,
  FIELD_TYPE_INPUT_NUMBER,
  FIELD_TYPE_OBJECT_ARRAY,
  FIELD_TYPE_PROPERTY_MAPPING,
  FIELD_TYPE_SELECT,
  FIELD_TYPE_STRING_ARRAY_VALUE_MAPPING,
  FIELD_TYPE_SWITCH,
  FIELD_TYPE_TEXT_AREA,
  FIELD_TYPE_WRAPPER,
  SEPARATOR,
  VALIDATION_PATTERN,
  VALIDATION_MIN,
  VALIDATION_MAX,
  VALIDATION_MIN_LEN,
  VALIDATION_MAX_LEN
} from 'pages/JsonToForm/utils'

import { notifyError } from 'src/utils/notify'
import JsonEditor from 'components/JsonEditor.vue'
import ConfigSearch from 'pages/JsonToForm/ConfigSearch.vue'
import ConfigView from 'pages/JsonToForm/Helpers/ConfigView.vue'
import { i18n } from 'src/boot/i18n'

export default {
    name: 'ConfigForm',

    components: {
      ConfigView,
      ConfigSearch,
      JsonEditor,
      VueFormJsonSchema
    },

    props: {
      modelValue: {
        type: Object,
        required: false
      },

      schema: {
        type: Object,
        required: true
      },

      expertMode: {
        type: Boolean,
        required: false,
        default: false
      },

      showSearch: {
        type: Boolean,
        required: false,
        default: false
      },

      showViewModeOption: {
        type: Boolean,
        required: false,
        default: false
      },

      viewMode: {
        type: String,
        required: false,
        default: () => 'mode_split'
      },

      showTop: {
        type: Boolean,
        required: false,
        default: true
      }
    },

    emits: [
      'update:model-value',
      'is-valid-config',
      'has-change'
    ],

    data() {
      return {
        localProperties: this.schema.properties,
        formModel: this.modelValue || {},
        generatedObject: "",
        options: {
          ajv: {
            options: {
              strict: false
            }
          }
        },
        activeTab: null,
        uiSchemas: [],
        tabDictionary: {},
        selectableTabs: [],
        splitterValue: 300,
        SEPARATOR
      }
    },

    computed: {
      computedSchema() {
        return {
          "$schema": this.schema.$schema,
          "$id": this.schema.$id,
          "title": "Basic Config",
          "type": "object",
          "properties": this.localProperties,
          "required": this.schema.required
        }
      },

      hasValidLocalization() {
        const localization = this.schema.schemaLocalization

        return localization && localization.namespace && localization.messages
      }
    },

    methods: {
      buildModel(field, path) {
        // sanity check: make sure we have an object path
        if (!path) return

        let defaultValue = notNullOrUndefined(field.default) ? field.default : this.generateDefault(field.uiFieldType)

        if (
          field.properties ||
          field.patternProperties ||
          field.uiFieldType === 'dynamic-input' ||
          field.uiFieldType === 'object-mapping' ||
          field.uiFieldType === 'dynamic_object_mapping'
        ) defaultValue = field.type === 'array' ? [] : {}

        if (field.uiFieldType === FIELD_TYPE_OBJECT_ARRAY) defaultValue = [];

        const explodedPaths = path.split(SEPARATOR)

        this.createModel(this.formModel, explodedPaths, defaultValue)
      },

      createModel(model, paths, defaultValue = null) {
        // sanity check: do we have a valid path?
        if (paths.length) {
          const key = paths[0]

          if (model[key] === undefined) {
            model[key] = paths[1] ? {} : defaultValue
          }

          paths.shift()

          if (paths.length) this.createModel(model[key], paths, defaultValue)
        }
      },

      generateDefault(fieldType) {
        if (['input'].includes(fieldType)) return null
        if (fieldType === 'checkbox') return false

        return null
      },

      buildUiSchema(required, loopBackProperties, modelName, parentUiSchema, parentTabs) {
        const uiElements = parentUiSchema || []
        // it is either the loop back properties or the schema properties
        const properties = loopBackProperties || this.localProperties

        for (const prop in properties) {
          if (typeof properties[prop] !== 'object') continue
          if (!properties[prop].tabLabels?.length) continue

          const propValue = properties[prop]
          const propName = modelName ? `${modelName}${SEPARATOR}${propValue.customKey || prop}` : prop
          const propProperties = propValue.properties || propValue.patternProperties
          let tabLabels = [...propValue.tabLabels]

          const validations = Object.keys(propValue).filter(prop => [
            VALIDATION_PATTERN,
            VALIDATION_MIN,
            VALIDATION_MAX,
            VALIDATION_MIN_LEN,
            VALIDATION_MAX_LEN
          ].includes(prop) && propValue[prop])
            .map(validator => {
              return {
                name: validator,
                conditional: propValue[validator]
              }
            })

          if (required?.length && required.includes(prop)) {
            validations.unshift('required')
          }

          let uiElement = this.defineField(propValue, propName, validations)
          let shouldBeTabbed = false

          if (!parentTabs) {
            shouldBeTabbed = true
            uiElements.push(uiElement)
          } else {
            if (tabLabels.length > 1) shouldBeTabbed = true
            if (tabLabels.includes(parentTabs?.at(-1))) {
              uiElements.push(uiElement)
              if (tabLabels.length > 1) tabLabels = tabLabels.filter(label => label !== parentTabs.at(-1))
            } else {
              shouldBeTabbed = true
            }
          }

          if (propProperties && propValue.isObject) {
            this.buildUiSchema(propValue.required, propProperties, propName, uiElement.children, tabLabels)
          }

          if (shouldBeTabbed) {
            this.buildUiTab(uiElement, propValue, tabLabels)
          }

          if (isNullOrUndefined(this.modelValue) || isEmpty(this.modelValue)) this.buildModel(propValue, propName)
        }

        return uiElements
      },

      buildUiTab(uiElement, propValue, tabs) {
        const localPropValue = cloneDeep(propValue)

        tabs.forEach(label => {
          const tabName = label.toLowerCase().replaceAll(' ', '_')
          this.addNewElement(tabName, label, uiElement, localPropValue)
        })
      },

      addNewElement(name, label, uiElement, configValue) {
        const index = this.tabDictionary[name]

        if (index > -1) {
          this.uiSchemas[index].elements.push(uiElement)
          this.uiSchemas[index].label = this.getLocalizedValue(configValue.localization?.[name], label)
        } else {
          this.tabDictionary[name] = this.uiSchemas.length ? this.uiSchemas.length : 0
          this.uiSchemas.push({
            id: uuid(),
            label: this.getLocalizedValue(configValue.localization?.[name], label),
            name,
            elements: [uiElement]
          })
        }
      },

      getLocalizedValue(path, fallbackValue) {
        if (!this.hasValidLocalization || !path) return fallbackValue
        if (this.$t(path) === path) return fallbackValue

        return this.$t(path)
      },

      defineField(propValue, modelName, validations) {
        const localization = propValue.localization

        const uiStructure = {
          id: modelName,
          component: null,
          model: modelName,
          valueProp: 'model-value',
          tabLabels: propValue.tabLabels,
          searchKeys: propValue.searchKeys,
          fieldOptions: {
            props: {
              label: this.getLocalizedValue(localization?.label, propValue.label),
              description: propValue.description ? marked.parse(this.getLocalizedValue(localization?.description, propValue.description)) : ''
            },
            attrs: {},
            on: {}
          },
          children: []
        }

        if (propValue.uiFieldType === FIELD_TYPE_WRAPPER) {
          uiStructure.component = 'wrapper'
          uiStructure.fieldOptions.attrs.id = modelName
          uiStructure.fieldOptions.props.defaultCollapsed = propValue.defaultCollapsed
        }

        if ([FIELD_TYPE_INPUT, FIELD_TYPE_TEXT_AREA].includes(propValue.uiFieldType)) {
          uiStructure.component = 'sq-input-field'
          uiStructure.fieldOptions.attrs.for = modelName
          uiStructure.fieldOptions.props.validations = validations
          uiStructure.fieldOptions.props.defaultValue = propValue.default
          uiStructure.fieldOptions.on['update:model-value'] = (value) => value

          if (propValue.shouldMask) uiStructure.fieldOptions.props.type = 'password'
          if (propValue.uiFieldType === FIELD_TYPE_TEXT_AREA) uiStructure.fieldOptions.props.type = 'textarea'
        }

        if (propValue.uiFieldType === FIELD_TYPE_INPUT_NUMBER) {
          uiStructure.component = 'sq-input-number-field'
          uiStructure.fieldOptions.attrs.for = modelName
          uiStructure.fieldOptions.props.type = 'number'
          uiStructure.fieldOptions.props.validations = validations
          uiStructure.fieldOptions.props.defaultValue = propValue.default
          uiStructure.fieldOptions.on['update:model-value'] = (value) => value
        }

        if ([FIELD_TYPE_CHECKBOX, FIELD_TYPE_SWITCH].includes(propValue.uiFieldType)) {
          uiStructure.component = propValue.uiFieldType === FIELD_TYPE_CHECKBOX ? 'sq-checkbox' : 'sq-switch'
          uiStructure.fieldOptions.attrs.id = modelName
          uiStructure.fieldOptions.on['update:model-value'] = (value) => value
        }

        if (propValue.uiFieldType === FIELD_TYPE_SELECT) {
          uiStructure.component = propValue.isSelectCanAdd ? 'sq-select-with-add' : 'sq-select'
          uiStructure.fieldOptions.attrs.for = modelName
          uiStructure.fieldOptions.props.validations = validations
          uiStructure.fieldOptions.props.options = propValue.defaultOptions
          uiStructure.fieldOptions.props.defaultValue = propValue.default
          uiStructure.fieldOptions.props.multiple = propValue.isSelectMultiple
          uiStructure.fieldOptions.props.useChips = propValue.isSelectMultiple
          uiStructure.fieldOptions.on['update:model-value'] = (value) => value

          if (propValue.isSelectCanAdd) {
            uiStructure.fieldOptions.on['update:options'] = (value) => {
              propValue.defaultOptions = value.options

              return value.currentValue
            };
          }
        }

        if (propValue.uiFieldType === FIELD_TYPE_DYNAMIC_INPUT) {
          uiStructure.component = 'sq-dynamic-input'
          uiStructure.fieldOptions.attrs.id = modelName
          uiStructure.fieldOptions.props.validations = validations
          uiStructure.fieldOptions.props.label = propValue.label
          uiStructure.fieldOptions.props.hint = propValue.description
          uiStructure.fieldOptions.on['update:model-value'] = (value) => value
        }

        if (propValue.uiFieldType === FIELD_TYPE_STRING_ARRAY_VALUE_MAPPING) {
          uiStructure.component = 'sq-input-mapping'
          uiStructure.fieldOptions.attrs.id = modelName
          uiStructure.fieldOptions.props.validations = validations
          uiStructure.fieldOptions.on['update:model-value'] = (value) => value
        }

        if (propValue.uiFieldType === FIELD_TYPE_PROPERTY_MAPPING) {
          uiStructure.component = 'sq-property-mapping'
          uiStructure.fieldOptions.attrs.id = modelName
          uiStructure.fieldOptions.props.defaultProperties = propValue.defaultProperties || []
          uiStructure.fieldOptions.on['update:model-value'] = (value) => value
        }

        if (propValue.uiFieldType === FIELD_TYPE_DATE_PICKER) {
          uiStructure.component = 'sq-date-picker'
          uiStructure.fieldOptions.attrs.id = modelName
          uiStructure.fieldOptions.props.validations = validations
          uiStructure.fieldOptions.on['update:model-value'] = (value) => value
        }

        if (propValue.uiFieldType === FIELD_TYPE_DYNAMIC_OBJECT_MAPPING) {
          uiStructure.component = 'sq-dynamic-object-mapping'
          uiStructure.fieldOptions.attrs.id = modelName
          uiStructure.fieldOptions.on['update:model-value'] = (value) => value
        }

        if (propValue.uiFieldType === FIELD_TYPE_OBJECT_ARRAY) {
          uiStructure.component = 'sq-object-array'
          uiStructure.fieldOptions.attrs.id = modelName
          uiStructure.fieldOptions.props.properties = propValue.objectProperties
          uiStructure.fieldOptions.on['update:model-value'] = (value) => value
        }

        return uiStructure
      },

      handleStateChange: debounce(function() {
        if (this.viewMode === 'mode_split') this.generateObject()

        if (!isEqual(this.formModel, this.modelValue)) {
          this.handleHasChange()
        }

        this.$emit('update:model-value', this.formModel)
      }, 500),

      generateObject() {
        this.generatedObject = JSON.stringify(this.constructJson(), null, 2)
      },

      constructJson(baseObject = {}, passedDownObj) {
        const mappableObject = passedDownObj || this.formModel

        for (const obj in mappableObject) {
          const objValue = mappableObject[obj]
          const isArray = Array.isArray(objValue);

          if (objValue && typeof objValue === 'object') {
            const base = Array.isArray(objValue) ? [] : {}
            baseObject[obj] = Array.isArray(objValue) ? [] : {}

            baseObject[obj] = this.constructJson(base, objValue)
          } else {
            isArray ? baseObject.push(objValue) : baseObject[obj] = objValue
          }
        }

        const isAllEmpty = Object.keys(baseObject).every(key => {
          return isNullOrUndefined(baseObject[key])
        })

        return isAllEmpty ? null : baseObject
      },

      handleJsonEditorConfigChange() {
        try {
          this.formModel = JSON.parse(this.generatedObject) || {};

          if (!isEqual(this.formModel, this.modelValue)) this.handleHasChange()

          this.$emit('update:model-value', this.formModel)
        } catch (error) {
          console.error(error)

          notifyError('Invalid JSON detected.')
        }
      },

      handleTabChange() {
        const activeTab = this.uiSchemas.find(tab => tab.id === this.activeTab)

        this.$router.push({ query: { active_tab: activeTab?.name || this.uiSchemas[0]?.name } })
      },

      handleSearch(config) {
        if (!config) return

        if (this.activeTab !== config.tab.id) {
          this.activeTab = config.tab.id
        }

        setTimeout(() => {
          this.scrollToCurrentTab()

          const element = document.getElementById(config.id)

          if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' })
            element.classList.add('animate-glow')

            setTimeout(() => {
              element.classList.remove('animate-glow')
            }, 2000)
          }
        }, 200)
      },

      scrollToCurrentTab() {
        setTimeout(() => {
          document.getElementById(this.activeTab)
            ?.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }, 100);
      },

      initOptions() {
        this.initViewMode()
      },

      initViewMode() {
        this.splitterValue = this.viewMode === 'mode_split' && this.showViewModeOption ? 350 : 0
      },

      handleHasChange() {
        this.$emit('has-change', true)
      },

      schemaLocalization() {
        const localization = this.schema.schemaLocalization

        if (!this.hasValidLocalization) return

        const namespace = localization.namespace
        const messages = this.schema.schemaLocalization.messages
        const locales = Object.keys(messages)

        for (const locale of locales) {
          if (!messages[locale]) continue

          i18n.global.mergeLocaleMessage(locale,
            {
              [namespace]: messages[locale]
            }
          )
        }
      }
    },

    watch: {
      modelValue: {
        handler(value, oldValue) {
          if (value && JSON.stringify(value) !== JSON.stringify(oldValue)) {
            this.formModel = notNullOrUndefined(value) ? value : {}
          }
        },

        deep: true,
        immediate: true
      },

      expertMode(value) {
        if (value) this.generateObject()
      },

      viewMode(value) {
        if (value === 'mode_split') this.generateObject()
        this.initViewMode()
      },

      activeTab() {
        this.handleTabChange()
      }
    },

    created() {
      this.schemaLocalization()
      this.buildUiSchema(this.computedSchema.required, null)

      setTimeout(() => {
        const activeTab = this.uiSchemas.find(tab => tab.name === this.$route.query.active_tab)
        this.activeTab = activeTab?.id || this.uiSchemas[0]?.id
        this.scrollToCurrentTab()
      }, 100)

      this.initOptions()
    }
  }
</script>

<template>
  <div>
    <q-card
      v-if="!expertMode"
      id="tabListContainer"
      bordered
      class="config-tab-list"
    >
      <q-tabs
        v-if="!expertMode"
        v-model="activeTab"
        dense
        vertical
        align="right"
      >
        <q-tab
          v-for="ui in uiSchemas"
          :id="ui.id"
          :key="ui.id"
          :name="ui.id"
          :label="ui.label"
          :content-class="`${ui.id === activeTab ? 'text-primary' : ''}`"
          :data-cy="'J2FKey' + ui.label"
        >
          <q-tooltip :delay="1000">
            {{ ui.label }}
          </q-tooltip>
        </q-tab>
      </q-tabs>
    </q-card>

    <div
      class="config-content"
      :class="{
        'expert-mode': !expertMode
      }"
    >
      <q-form ref="jsonToFormRef">
        <q-splitter
          ref="splitterViewRef"
          v-if="!expertMode"
          :model-value="splitterValue"
          unit="px"
          reverse
          :limits="[300, Infinity]"
          before-class="before-class"
          :separator-style="`${viewMode === 'mode_split' ? 'width: 1px' : 'width: 0'};`"
          class="full-width"
        >
          <template #before>
            <q-card bordered>
              <q-card-section>
                <div
                  v-if="uiSchemas.length && showTop"
                  class="q-mb-md q-mt-md"
                >
                  <config-search
                    v-if="showSearch"
                    :ui-schemas="uiSchemas"
                    @search="handleSearch"
                  />
                </div>

                <q-tab-panels
                  v-model="activeTab"
                  animated
                  keep-alive
                >
                  <q-tab-panel
                    v-for="ui in uiSchemas"
                    :key="ui.id"
                    :name="ui.id"
                    class="q-pa-none"
                  >
                    <q-card>
                      <q-card-section>
                        <vue-form-json-schema
                          :ref="ui.name"
                          v-model="formModel"
                          :model-value="formModel"
                          :schema="{}"
                          :ui-schema="ui.elements.filter(el => el.component !== 'wrapper' || (el.component === 'wrapper' && el.children.length))"
                          :options="options"
                          :separator="SEPARATOR"
                          @update:state="handleStateChange"
                          :data-cy="'J2FValue' + ui.label"
                        />
                      </q-card-section>
                    </q-card>
                  </q-tab-panel>
                </q-tab-panels>
              </q-card-section>
            </q-card>
          </template>
          <template #after>
            <config-view
              :json-config="generatedObject"
              class="q-ml-md"
            />
          </template>
        </q-splitter>
      </q-form>

      <div v-if="expertMode">
        <json-editor
          v-model="generatedObject"
          max-height="none"
          @is-valid-json="(valid) => $emit('is-valid-config', valid)"
          @update:model-value="handleJsonEditorConfigChange"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  .config-tab-list {
    width: 200px;
    position: absolute;

    .q-tabs {
      max-height: 50vh;
      overflow-y: auto;
      overflow-x: hidden;

      .q-tab {
        justify-content: flex-start;
      }

      .q-tab__indicator {
        display: none;
      }
    }
  }

  .config-content {
    transition: all 0.8s ease;
  }

  .expert-mode {
    margin-left: 220px;
    transition: all 0.8s ease;
  }
</style>
