<script>
  import { v4 as uuid } from 'uuid'
  import { debounce } from 'lodash'
  import ConfirmationDialog from 'components/Functional/ConfirmationDialog.vue'
  import SqInputField from 'pages/JsonToForm/Fields/SqInputField.vue'
  import SqMarkdownDisplay from 'components/Common/SqMarkdownDisplay.vue'

  export default {
    name: 'SqDynamicInput',

    components: { SqMarkdownDisplay, ConfirmationDialog, SqInputField },

    props: {
      modelValue: {
        type: Array,
        required: false
      },

      label: {
        type: String,
        required: true
      },

      disable: {
        type: Boolean,
        required: false,
        default: false
      },

      validations: {
        type: Array,
        required: false,
        default: () => []
      }
    },

    data() {
      return {
        showDeleteConfirmation: false,
        selectedId: null,
        fields: []
      }
    },

    computed: {
      tempData() {
        return this.fields.reduce((acc, cur) => cur.model ? [...acc, cur.model] : acc, []).map(() => 'i').join('')
      },

      validValueLen() {
        return this.fields.reduce((acc, cur) => cur.model ? [...acc, cur.model] : acc, []).length
      },

      minLenValidation() {
        return this.validations?.find(val => val?.name === 'minLen')
      },

      maxLenValidation() {
        return this.validations?.find(val => val?.name === 'maxLen')
      },

      isValid() {
        let isValid = true

        if (this.validations?.includes('required') && !this.validValueLen) return false
        if (this.minLenValidation && this.validValueLen < this.minLenValidation.conditional) return false
        if (this.maxLenValidation && this.validValueLen > this.maxLenValidation.conditional) return false

        return isValid
      }
    },

    methods: {
      initializeValues (values) {
        if (!this.fields.length) {
          values?.forEach((val) => {
            this.addNewField(val)
          })
        } else {
          const maxIndex = this.fields.length - 1
          values?.forEach((val, index) => index <= maxIndex ? this.fields[index].model = val : this.addNewField(val))
          this.fields.splice(values?.length)
        }
      },

      addNewField (model = null) {
        this.fields.push({
          id: uuid(),
          model,
          label: `${this.label || 'Field'}`,
        })
      },

      handleConfirmDelete (id) {
        this.selectedId = id
        this.showDeleteConfirmation = true
      },

      deleteConfig () {
        const index = this.fields.findIndex(field => field.id === this.selectedId)

        if (index > -1) {
          this.fields.splice(index, 1)

          this.selectedId = null
          this.showDeleteConfirmation = false

          this.handleConfigChange()
        }
      },

      handleConfigChange: debounce(function () {
        const values = this.fields.reduce((acc, cur) => [...acc, cur.model], [])

        this.$emit('update:model-value', values)
      }, 300)
    },

    mounted() {
      this.initializeValues(this.modelValue)
    }
  }
</script>

<template>
  <q-card class="q-pa-sm">
    <sq-input-field
      v-show="false"
      v-model="tempData"
      removable
      :validations="validations"
    />

    <div class="text-grey-8">
      <span class="text-weight-bolder">
        {{ label }}

        <q-btn
          dense
          size="sm"
          color="primary"
          icon="add"
          label="Add"
          :disable="disable || (maxLenValidation && validValueLen >= maxLenValidation.conditional)"
          class="q-ml-sm"
          @click="addNewField(null)"
        />

        <span
          v-if="!isValid"
          class="text-red text-weight-bolder q-ml-sm"
        >
        Please add at least {{ minLenValidation?.conditional || 1 }} valid value
        <template v-if="maxLenValidation">
          and should not exceed {{ maxLenValidation.conditional }}
        </template>
      </span>
      </span>

      <div v-if="$attrs.description" class="text-caption">
        <sq-markdown-display :markdown="$attrs.description" />
      </div>
    </div>

    <div
      v-if="fields.length"
      class="row q-col-gutter-md"
    >
      <div
        v-for="(field, index) in fields"
        :key="field.id"
        class="col-4 col-sm-6 col-md-4"
      >
        <div class="relative-position">
          <sq-input-field
            v-model="field.model"
            :label="`${field.label} ${index + 1}`"
            :disable="disable"
            removable
            :validations="['required']"
            class="q-py-xs"
            @update:model-value="handleConfigChange"
            @remove="handleConfirmDelete(field.id)"
          />
        </div>
      </div>
    </div>

    <template v-else>
      <div class="text-center text-weight-bold">Click the add button to add new fields.</div>
    </template>

    <confirmation-dialog
      v-model="showDeleteConfirmation"
      title='Delete Config'
      type="negative"
      @confirm="deleteConfig"
      @close="showDeleteConfirmation = false"
    >
      <template #content>
        <span>
          Are you sure you want to remove this config?
        </span>
      </template>
    </confirmation-dialog>
  </q-card>
</template>
