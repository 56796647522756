<script>
  import { v4 as uuid } from 'uuid'
  import { debounce } from 'quasar'
  import ConfirmationDialog from 'components/Functional/ConfirmationDialog.vue'
  import SqMarkdownDisplay from 'components/Common/SqMarkdownDisplay.vue'
  import SqInputField from 'pages/JsonToForm/Fields/SqInputField.vue'

  export default {
    name: 'SqInputMapping',

    components: {
      SqInputField,
      SqMarkdownDisplay,
      ConfirmationDialog
    },

    props: {
      modelValue: {
        type: Object,
        required: false
      },

      label: {
        type: String,
        required: true
      },

      validations: {
        type: Array,
        required: false,
        default: () => []
      }
    },

    data() {
      return {
        showDeleteConfirmation: false,
        selectedParent: null,
        selectedField: null,
        toDeleteType: null,
        form: []
      }
    },

    computed: {
      deleteConfirmationMessage() {
        return this.toDeleteType === 'parent' ? 'Are you sure want to delete this config?' : 'Are you sure you want to delete this field?'
      }
    },

    methods: {
      initializeValues(values) {
        let index = 0

        for (const config in values) {
          const configValues = values[config]
          const formConfig = this.form[index]

          if (formConfig) {
            formConfig.model = config
            this.processValues(formConfig, configValues)
          } else {
            this.addNewParent(config)
            this.processValues(this.form.at(-1), configValues)
          }

          index++
        }
      },

      processValues(item, values) {
        values?.forEach((value, index) => {
          if (item.children[index]) {
            item.children[index].model = value
          } else {
            this.addNewField(item, value)
          }
        })
      },

      addNewParent(model = null) {
        this.form.push({
          id: uuid(),
          model,
          label: this.label,
          children: []
        })
      },

      addNewField(item, model = null) {
        item.children.push({
          id: uuid(),
          model,
          label: `${item.model || 'Field'} Item`
        })
      },

      handleConfirmDelete(item, field) {
        this.toDeleteType = field ? 'field' : 'parent'
        this.showDeleteConfirmation = true
        this.selectedParent = item
        this.selectedField = field
      },

      deleteConfig() {
        const parentIndex = this.form.findIndex(item => item.id === this.selectedParent.id)
        const fieldIndex = this.form[parentIndex]?.children?.findIndex(field => field.id === this.selectedField?.id)

        if (fieldIndex > -1) {
          this.form[parentIndex].children.splice(fieldIndex, 1)
        }
        else if (parentIndex > -1) {
          this.form.splice(parentIndex, 1)
        }

        this.handleConfigChange()
        this.closeDeleteConfirmation()
      },

      closeDeleteConfirmation() {
        this.toDeleteType = null
        this.selectedField = null
        this.selectedParent = null
        this.showDeleteConfirmation = false
      },

      handleConfigChange: debounce(function (item) {
        if (item && item.children?.length) {
          item.children = item.children.map(child => {
            return {
              ...child,
              label: `${item.model} Item`
            }
          })
        }

        const values = this.generateConfig()

        this.$emit('update:model-value', values)
      }, 300),

      generateConfig() {
        const values = {}

        this.form.forEach(item => {
          const hasChildItem = item.children.some(child => child.model)

          if (item.model && hasChildItem) {
            values[item.model] = item.children.reduce((acc, cur) => [...acc, cur.model], [])
          }
        })

        return values
      }
    },

    mounted() {
      this.initializeValues(this.modelValue)
    }
  }
</script>

<template>
  <q-card class="q-pa-sm q-my-md">
    <q-expansion-item
      default-opened
      dense
      dense-toggle
      switch-toggle-side
      header-class="expansion-header"
    >
      <template #header>
        <div class="flex items-center full-width">
          <div class="text-weight-bolder">
            {{ label }}

            <q-btn
              dense
              size="sm"
              color="primary"
              icon="add"
              :label="`Add ${label}`"
              class="q-ml-sm"
              @click.stop="addNewParent(null)"
            />
          </div>
        </div>
      </template>

      <q-card-section class="q-pa-none">
        <div v-if="$attrs.description" class="text-caption">
          <sq-markdown-display :markdown="$attrs.description" />
        </div>
      </q-card-section>

      <q-card-section>
        <q-card
          v-for="(item, index) in form"
          :key="item.id"
          class="q-pa-sm"
          :class="{
          'q-mt-md': index > 0
        }"
        >
          <sq-input-field
            v-model="item.model"
            :label="`${item.label} (${index + 1})`"
            removable
            :validations="[{ name: 'noSpace' }, 'required']"
            @update:model-value="handleConfigChange(item)"
            @remove="handleConfirmDelete(item)"
          />

          <div class="q-pl-md">
            <div
              v-if="item.children.length"
              class="row q-col-gutter-md"
            >
              <div
                v-for="(field, index) in item.children"
                :key="field.id"
                class="col-4 col-sm-6 col-md-4"
              >
                <div class="relative-position">
                  <sq-input-field
                    v-model="field.model"
                    :label="`${field.label} ${index + 1}`"
                    class="q-py-sm"
                    removable
                    :validations="['required']"
                    @update:model-value="handleConfigChange"
                    @remove="handleConfirmDelete(item, field)"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="flex q-mt-md">
            <q-btn
              dense
              size="sm"
              :disable="!item.model"
              color="primary"
              icon="add"
              label="Add Mapped Value"
              @click="addNewField(item)"
            />
          </div>
        </q-card>
      </q-card-section>
    </q-expansion-item>

    <confirmation-dialog
      v-model="showDeleteConfirmation"
      :title="toDeleteType === 'parent' ? 'Delete Config' : 'Delete Field'"
      type="negative"
      @confirm="deleteConfig"
      @close="closeDeleteConfirmation"
    >
      <template #content>
        <span>
          {{ deleteConfirmationMessage }}
        </span>
      </template>
    </confirmation-dialog>
  </q-card>
</template>
