<script>
import { defineComponent } from 'vue'
import { mapState } from 'vuex'
import MenuLink from './components/MenuLink.vue'
import EntitySelection from 'components/EntitySelection.vue'
import DropdownMenu from 'layouts/menu/components/DropdownMenu'

const navListPublic = [
  {
    title: 'Login',
    link: '/login',
    icon: 'login'
  }
]
const navList = [
  {
    title: 'Dashboard',
    icon: 'img:assets/icons/menu/dashboard.svg',
    link: '/dashboard',
    name: 'dashboard'
  },
  {
    title: 'Flows',
    icon: 'img:assets/icons/menu/flows.svg',
    link: '/flow',
    name: 'flows'
  },
  {
    title: 'Flow Executions',
    icon: 'img:assets/icons/menu/flow-executions.svg',
    link: '/flowexecutions',
    name: 'flow-executions'
  },
  {
    title: 'Entities',
    icon: 'img:assets/icons/menu/entities.svg',
    name: 'entities',
    link: '/documents',
  },
  {
    title: 'Cronjobs',
    icon: 'img:assets/icons/menu/cronjobs.svg',
    link: '/cronjobs',
    name: 'cron-jobs'
  },
  {
    title: 'Filebrowser',
    icon: 'img:assets/icons/menu/filebrowser.svg',
    link: '/browser',
    name: 'file-browser'
  },
  {
    title: 'Logs',
    icon: 'img:assets/icons/menu/logs.svg',
    link: '/logs',
    name: 'logs'
  },
  {
    title: 'Users',
    icon: 'img:assets/icons/menu/users.svg',
    link: '/user',
    name: 'users'
  },
  {
    title: 'Validation',
    icon: 'rule',
    link: 'validation',
    name: 'validation-info',
    children: [
      {
        title: 'Validation Info',
        icon: 'grading',
        link: '/validation/infos',
        name: 'validation-info'
      },
      {
        title: 'Validation Stats',
        icon: 'addchart',
        link: '/validation/stats',
        name: 'validation-stats'
      },
    ]
  },
  {
    title: 'Failed Messages',
    icon: 'running_with_errors',
    link: '/failed-messages',
    name: 'failed-messages'
  },
  {
    title: 'Form Builder',
    icon: 'construction',
    link: '/form-builder',
    name: 'form-builder'
  }
];

export default defineComponent({
  name: 'MainMenu',

  components: {
    MenuLink,
    EntitySelection,
    DropdownMenu
  },

  setup () {
    return {
      menuLinksPublic: navListPublic,
      menuLinks: navList
    }
  },

  computed: {
    ...mapState('preferences', ['isRefreshing']),

    isLoggedIn() {
      return (this.$store.state.authentication.user !== null) && (this.$store.state.authentication.status.loggingIn !== true)
    },
    isMobile() {
      return (this.$q.screen.xs || this.$q.screen.sm);
    }
  },

  methods: {
    checkIfToOpen(menuRoute) {
      return this.$route.path.indexOf(`${menuRoute}/`) > -1
    }
  }
})
</script>

<template>
  <div class="fit flex self-stretch">
    <q-list class="full-width">
      <q-item-label header class="flex text-weight-bold">
        <div class="app-logo-wrapper full-width">
          <q-img
              :src="!$q.dark.isActive ? 'assets/app-logo.svg' : 'assets/app-logo-light.svg'"
              class="app-logo cursor-pointer"
              position="0 50%"
              fit="contain"
              :alt="$t('general.appName')"
              @click.capture.stop="isLoggedIn ? $router.replace('/dashboard') : $router.replace('/login')"
          />
          <!--<span class="app-guest-menu-headline"><span class="app-name-accent1">syn</span><span class="app-name-accent2">Qup</span></span>-->
        </div>
      </q-item-label>

      <div class="app-menu" :class="{ 'disabled': isRefreshing }">
        <!-- Menu for guest -->
        <template v-if="!isLoggedIn" v-for="menuItem in menuLinksPublic">
          <menu-link :title="menuItem.title" :caption="menuItem.caption" :icon="menuItem.icon" :link="menuItem.link" />
        </template>
        <!-- Menu for logged in user -->
        <template
            v-if="isLoggedIn"
            v-for="menuItem in menuLinks"
            :key="menuItem.name"
        >
          <entity-selection
              class="q-mx-md"
              v-if="menuItem.title === 'Entities' && isMobile"
              :icon="menuItem.icon"
              data-cy="menuLinkEntities"
              is-menu
          />
          <dropdown-menu
              v-else-if="menuItem.children"
              :menu="menuItem"
              :is-opened="checkIfToOpen(menuItem.link)"
          />
          <menu-link
              v-else
              v-bind:data-cy="'menuLink' + menuItem.title"
              :title="menuItem.title"
              :caption="menuItem.caption"
              :icon="menuItem.icon"
              :link="menuItem.link"
          />
        </template>
      </div>
    </q-list>
    <div class="app-help-container q-ma-md flex self-end q-pb-md">
      <a class="app-help flex inline items-end" :href="$t('menu.helpLink')" target="_blank">
        <q-icon class="q-mr-md" inline name="help_outline" size="xs" />
        <div class="items-end full-height">{{ $t('menu.help') }}</div>
      </a>
    </div>
  </div>
</template>

<style lang="scss">
.q-drawer {
  .app-logo-wrapper {
    .app-logo {
      height: 3rem;
    }
  }
  .app-help {
    color: $gray;
    text-decoration: none;
    &:hover {
      color: $primary;
    }
  }
  .app-menu h2 {
    font-size: 1rem;
  }
  .q-item .q-item__section--side > .q-icon {
    font-size: 1.25rem;
    top: -1px;
  }
}
</style>
