<script>
  import { defineComponent } from 'vue'
  import { cronjobService } from 'src/services/cronjobService'
  import usePaginator from 'src/composables/usePaginator'

  import LoadingIndicator from 'components/LoadingIndicator.vue'
  import CronjobModal from './components/CronjobModal'
  import DeleteCronjobModal from './components/DeleteCronjobModal'
  import Paginator from 'components/Paginator.vue'

  export default defineComponent({
    name: 'Cronjobs',

    components: {
      LoadingIndicator,
      CronjobModal,
      DeleteCronjobModal,
      Paginator
    },

    setup () {
      const { pagination, readFromQuery, updateRouteQuery, clearQuery, buildQueryData } = usePaginator()

      return {
        pagination,
        readFromQuery,
        updateRouteQuery,
        clearQuery,
        buildQueryData
      }
    },

    data () {
      return {
        showCronJobModal: false,
        showDeleteConfirmation: false,
        selectedCronJob: null,
        cronjobList: [],
        isLoading: true,
        columns: [
          {
            name: 'id',
            required: true,
            label: 'ID',
            align: 'left',
            field: row => row['@id'].split("/").pop(),
            format: val => `${val}`
          },
          {
            name: 'cronExpression',
            required: true,
            label: 'Cron Expression',
            align: 'left',
            field: row => row.cronExpression,
            format: val => `${val}`
          },
          {
            name: 'active',
            required: true,
            label: 'Active',
            align: 'left',
            field: row => this.getActiveStatus(row.active),
            format: val => `${val}`
          },
          {
            name: 'flowId',
            required: true,
            label: 'Flow ID',
            align: 'right',
            field: row => row.flow.id,
            format: val => `${val}`
          },
          {
            name: 'flowName',
            required: true,
            label: 'Flow Name',
            align: 'left',
            field: row => row.flow.name,
            format: val => `${val}`
          },
          {
            name: 'action',
            required: true,
            label: '',
            align: 'left',
            field: 'action'
          }
        ]
      }
    },

    methods: {
      pageLoad() {
        this.isLoading = true

        this.updateRouteQuery(this.pagination)

        cronjobService.getCronjobs(this.pagination.page, this.pagination.itemsPerPage, (data) => {
          this.pagination.totalItems = data['hydra:totalItems'] ?? 0
          this.pagination.pageCount = Math.ceil(this.pagination.totalItems / this.pagination.itemsPerPage)
          // Update page
          if (data['hydra:member']?.length > 0 ) {
            this.cronjobList = data['hydra:member'];
            this.isLoading = false;
          } else {
            // There are no data available on current page anymore. Reload, jump to last one with data.
            if(data['hydra:totalItems'] > 0 && this.pagination.page !== 1) {
              this.pagination.page = this.pagination.pageCount;
              this.pageLoad();
            } else {
              // There are no items at all. Nothing to do here.
              // Need to empty the list Array when deleting the last available item though
              this.cronjobList = [];
              this.isLoading = false;
            }
          }
        })
      },

      getActiveStatus(val) {
        return val.toString() === 'true' ? this.$t('general.yes') : this.$t('general.no')
      },

      handleSaved() {
        this.showCronJobModal = false

        const totalItems = this.pagination.totalItems

        if (!this.selectedCronJob) {
          if (totalItems > 0 && (totalItems % this.pagination.itemsPerPage === 0)) {
            //  Creating first item or new page
            this.pagination.page = this.pagination.pageCount + 1
          }

          if (!(totalItems === 0 || (this.pagination.page === this.pagination.pageCount))) {
            // Currently on any previous page, jump to last page, since new created item is there
            this.pagination.page = this.pagination.pageCount
          }
        }

        this.pageLoad();

        this.selectedCronJob = null
      },

      updateCronjob(evt, cronjob) {
        this.showCronJobModal = true
        this.selectedCronJob = cronjob
      },

      deleteCronjob(cronjob) {
        this.showDeleteConfirmation = true
        this.selectedCronJob = cronjob
      },

      handleDeleted(data) {
        this.showDeleteConfirmation = false
        this.selectedCronJob = null

        if (data?.deleted === true && this.cronjobList.length === 1) {
          this.pagination.page = this.pagination.page > 1 ? this.pagination.page - 1 : 1
        }

        this.pageLoad();
      },

      resetPageAndReload() {
        this.pagination.page = 1
        this.pageLoad()
      }
    },

    mounted() {
      this.readFromQuery(this.pagination)
      this.pageLoad();
    },

    unmounted() {
      this.clearQuery();
    }
  })
</script>

<template>
  <q-page class="block">
    <div class="q-pa-md">
      <div class="flex">
        <h1>{{ $t('cronjob.headline') }}</h1>
        <p>{{ $t('cronjob.desc') }}</p>

        <div class="q-ml-auto q-mb-lg">
          <q-btn
            v-if="!isLoading"
            flat
            dense
            icon-right="add"
            :label="$t('cronjob.addCronjob')"
            :title="$t('cronjob.addCronjob')"
            class="app-action-btn q-pa-sm"
            @click.capture.stop="showCronJobModal = true"
          />
        </div>
      </div>

      <loading-indicator v-if="isLoading" wrapper />

      <div v-if="!isLoading">
        <!-- :row-key="cronjobList.id" -->
        <div v-if="(cronjobList && cronjobList.length > 0)">
          <q-card flat>
            <q-card-section>
              <q-table
                :rows="cronjobList"
                :row-key="cronjobList.id"
                :columns="this.columns"
                color="primary"
                hide-bottom
                hide-pagination
                :loading="isLoading"
                :rows-per-page-options="[pagination.itemsPerPage]"
                flat
                class="app-cronjobs-table col-12 transparent q-pa-sm"
                @row-click="this.updateCronjob"
              >
                <template v-slot:body-cell-action="props">
                  <q-td :props="props">
                    <div class="flex no-wrap">
                      <q-btn
                        icon-right="edit"
                        size="12px" flat dense
                        class="q-mr-sm q-mr-md-none"
                        :title="$t('cronjob.updateCronjob')"
                        :disabled="isLoading"
                        @click.capture.stop="this.updateCronjob(null, props.row)"
                      />
                      <q-btn
                        v-if="props.row"
                        icon-right="delete"
                        size="12px" no-caps flat dense
                        class="q-ml-sm negative-hover"
                        :title="$t('cronjob.deleteCronjob')"
                        @click.capture.stop="this.deleteCronjob(props.row)"
                      />
                    </div>
                  </q-td>
                </template>
              </q-table>
            </q-card-section>
          </q-card>

          <paginator
            v-model:page="pagination.page"
            v-model:itemsPerPage="pagination.itemsPerPage"
            :total-pages="pagination.pageCount"
            class="q-mt-lg"
            @update:page="pageLoad"
            @update:items-per-page="resetPageAndReload"
          />
        </div>

        <div v-if="!(cronjobList && cronjobList.length > 0)" class="app-empty-list flex justify-center items-center text-gray">{{ $t('cronjob.emptyList') }}</div>
      </div>
    </div>

    <cronjob-modal
      v-model="showCronJobModal"
      :cronjob="selectedCronJob"
      :total-pages="pagination.pageCount"
      :items-per-page="pagination.itemsPerPage"
      @saved="handleSaved"
      @hide="selectedCronJob = null"
    />

    <delete-cronjob-modal
      v-model="showDeleteConfirmation"
      :cronjob="selectedCronJob"
      @deleted="handleDeleted"
    />
  </q-page>
</template>

<style lang="scss">
  .app-empty-list {
    min-height: 100px;
  }
</style>
