<script>
import {defineComponent, onBeforeUnmount, ref} from 'vue'
import { checkRunningFlowLoop, clearLooping } from 'src/helpers/auto-refresh'
import { flowService } from "src/services";

import LoadingIndicator from 'components/LoadingIndicator.vue'
import StatisticCard from 'components/StatisticCard.vue'
import DateDisplay from 'components/DateDisplay.vue'
import JdmItem from './components/JdmItem/JdmItem.vue'
import JsonEditor from 'components/JsonEditor.vue'
import CreateJobDispatcherMappingModal from './components/JobDispatcherMappingModal/CreateJobDispatcherMappingModal'
import ExportFlowModal from '../Overview/components/FlowModal/ExportFlowModal'
import EndFlowModal from '../Overview/components/FlowModal/EndFlowModal'
import FlowModal from '../Overview/components/FlowModal/FlowModal'
import FlowItemDetails from '../Overview/components/FlowItemDetails'

export default defineComponent({
  name: 'Detail',

  components: {
    FlowModal,
    EndFlowModal,
    LoadingIndicator,
    StatisticCard,
    DateDisplay,
    JdmItem,
    ExportFlowModal,
    JsonEditor,
    FlowItemDetails,
    CreateJobDispatcherMappingModal
  },

  setup () {
    return {
      // ref documentation: https://quasar.dev/start/how-to-use-vue#handling-vue-properties
      textareaInput: ref('')
    }
  },

  data () {
    onBeforeUnmount(() => {
      clearLooping(this.autoRefreshLooping);
    });
    return {
      showJdmModal: false,
      showFlowModal: false,
      showEndFlowModal: false,
      showExportFlowModal: false,
      configurationModel: this.configuration,
      originalConfiguration: null,
      configurationKey: 0,
      currentFlow: null,
      getMappingsOfProcessStep: (processStep) => {
        if (this.currentFlow && ('jobDispatcherMappings' in this.currentFlow)) {
          return this.currentFlow.jobDispatcherMappings.filter(jdm => jdm.processStepIdentifier === processStep)
        }
        return []
      },
      autoRefreshLooping: null,
      runningFlow: null,
      configuration: null,
      isRunning: null,
      isLoading: true,
      validJson: null,
      flowBlocked: false,
      processStep: ref(),
      expandConf: ref(),
      expandInput: ref(),
      expandTransform: ref(),
      expandOutput: ref()
    }
  },

  computed: {
    getInputMappings() {
      return this.getMappingsOfProcessStep('input')
    },

    getTransformationMappings() {
      return this.getMappingsOfProcessStep('transformations')
    },

    getOutputMappings() {
      return this.getMappingsOfProcessStep('output')
    },
    configurationEmpty() {
      return !this.configurationModel || this.configurationModel === '';
    },
    configurationChanged() {
      return this.configurationModel !== this.originalConfiguration &&
        !(this.originalConfiguration === null || typeof this.originalConfiguration === 'undefined');
    },
    configurationValid() {
      return this.validJson;
    }
  },

  methods: {
    pageLoad(payload = null, softReload = false) {
      if(!softReload) this.isLoading = true;
      const getData = () => {
        flowService.getFlowDetails(this.$route.params.id, (data) => {
          this.isRunning = data.isRunning;
          let flowRunning = false;
          if(data['@type'] === "hydra:Error") {
            this.$router.push({
              name: 'ERROR',
              params: { n: true }
            })
          } else {
            if(this.originalConfiguration === null || typeof this.originalConfiguration === 'undefined') this.originalConfiguration = JSON.stringify(data.config, null, 2);
            if(data.isRunning === true) flowRunning = true;
            else {
              // Break loop check once flow is finished
              clearLooping(this.autoRefreshLooping);
            }
            this.currentFlow = data;
            if(!softReload) this.configuration = data.config; // Only necessary for init
          }
          this.runningFlow = flowRunning;
          if(this.runningFlow === true) this.autoRefreshLooping = checkRunningFlowLoop(this.runningFlow, () => this.pageLoad(null, true));
          this.isLoading = false;
        })
      }
      if(typeof payload?.deleted !== "undefined") {
        flowService.deleteJobDispatcherMapping(payload.jdmId, (data) => {
          this.isLoading = false;
          if(data?.data['@type'] === "hydra:Error") {
            this.$store.dispatch('alert/error', 'flow.overview.detail.modal.jdmDeletionFailed');
          } else {
            getData();
            this.$store.dispatch('alert/success', 'flow.overview.detail.modal.jdmDeletionSuccess');
          }
        });
      } else {
        getData();
      }
    },
    startFlow() {
      this.flowBlocked = true;
      flowService.startFlow(this.currentFlow.id, (data) => {
        if(data.success !== 0) {
          this.$store.dispatch('alert/success', "flow.flowStartedSuccess", { root: true });
          this.pageLoad();
        } else {
          this.$store.dispatch('alert/error', data.msg, { root: true });
        }
        this.flowBlocked = false;
      })
    },

    handleFlowEnded() {
      this.showEndFlowModal = false
      this.pageLoad()
    },

    createJdmModal(step) {
      this.processStep = step;
      this.showJdmModal = true
    },

    handleJdmCreated() {
      this.showJdmModal = false
      this.pageLoad()
    },

    updateFlowConfig() {
      try {
        const parsedConfig = JSON.parse(this.configurationModel);
        this.isLoading = true;
        flowService.updateFlowConfig({ flowId: this.$route.params.id, config: parsedConfig}, (data) => {
          if (data.id) {
            this.$store.dispatch('alert/success', "flow.overview.detail.configuration.updateSuccess", { root: true });
            this.originalConfiguration = this.configurationModel;
          } else {
            this.$store.dispatch('alert/error', "flow.overview.detail.configuration.updateFail", { root: true });
          }
          this.isLoading = false;
        })
      } catch(e) {
        this.$store.dispatch('alert/error', "flow.overview.detail.configuration.updateFailJsonSyntax");
      }
    },
    updateConfigurationEditor() {
      this.configurationModel = (typeof this.configuration !== "undefined" && this.configuration !== null) ? JSON.stringify(this.configuration, null, 2) : "{}";
      this.configurationKey += 1; // Necessary for editor model view update - automatically triggers on key change
    },

    handleUpdated() {
      this.showFlowModal = false
      this.pageLoad()
    },

    checkValidJson(valid) {
      this.validJson = valid;
    }
  },
  mounted() {
    this.pageLoad();
  },
  watch: {
    configuration: function() {
      this.updateConfigurationEditor();
    }
  }
})
</script>

<template>
  <q-page class="block app-flow-detail">
    <div class="app-container-wrapper">
      <div class="app-headline-container q-pa-md flex row" v-if="currentFlow !== null">
        <div class="full-width flex justify-between items-center">
            <h1 class="q-mr-sm">{{ currentFlow.name }}</h1>

            <div class="controls q-my-md q-my-lg-none q-mb-xs-lg">

              <q-btn v-if="!this.currentFlow.isRunning"
                flat dense
                class="app-action-btn q-pa-sm q-mr-sm"
                :label="$t('flow.overview.detail.start')"
                :disable="isLoading"
                @click="!this.currentFlow.isRunning ? startFlow() : null"
                v-bind:data-cy="'buttonQuickStartFlow' + this.currentFlow.id"
              ><q-icon name="arrow_right_alt" class="q-ml-sm" />
              </q-btn>

              <q-btn v-if="this.currentFlow.isRunning"
                flat dense
                class="app-action-btn q-pa-sm q-mr-sm"
                :label="$t('flow.overview.detail.end')"
                :disable="isLoading"
                @click="showEndFlowModal = true"
                v-bind:data-cy="'buttonQuickEndFlow' + this.currentFlow.id"
              ><q-icon name="do_disturb" class="q-ml-sm" />
              </q-btn>

              <q-btn flat dense rounded
                class="app-action-btn app-export-flow-btn q-pa-sm q-mr-sm"
                icon-right="file_upload"
                :label="$t('flow.overview.detail.export')"
                :title="$t('flow.overview.detail.export')"
                @click.capture.stop="showExportFlowModal = true"
              />

              <q-btn
                flat dense
                class="app-action-btn app-edit-flowname q-pa-sm q-mr-sm"
                :label="$t('flow.overview.detail.renameFlow')"
                :title="$t('flow.overview.detail.renameFlow')"
                @click="showFlowModal = true"
                ><q-icon name="img:assets/icons/edit.svg" class="q-ml-sm" />
              </q-btn>
            </div>

          <div class="row justify-between items-center full-width q-mt-md q-mt-md-sm last-execution-info" v-if="currentFlow.lastFlowExecutionId">
            <flow-item-details :flow="currentFlow"  :flow-blocked="flowBlocked" showElapsedTime></flow-item-details>
          </div>
        </div>
      </div>
    </div>


    <loading-indicator v-if="currentFlow === null" />
    <template v-if="currentFlow !== null">
      <div class="app-expansion-section">
        <q-expansion-item
            class="q-mb-md q-mx-md"
            v-model="expandConf"
            switch-toggle-side
            default-opened
            data-cy="sectionConfiguration"
        >

          <template v-slot:header>
            <q-item-section class="one-liner">
              <div class="one-liner-child">{{ $t('flow.overview.detail.configuration.title') }}</div>
            </q-item-section>
          </template>
          <q-card class="bg-transparent">
            <q-card-section>
              <div class="relative-position flex justify-center items-center q-px-lg q-py-md">
                <json-editor contrast wrapped
                             class="q-mt-md"
                             v-model="configurationModel"
                             :key="'flowConfig' + configurationKey"
                             max-height="100%"
                             :disabled="isLoading"
                             allow-empty
                             @is-valid-json="checkValidJson"
                />
                <div class="flex full-width q-mt-md justify-end">
                  <q-btn flat dense
                         icon-right="update"
                         :label="$t('flow.overview.detail.configuration.button')"
                         :title="$t('flow.overview.detail.configuration.button')"
                         class="app-action-btn q-pa-sm"
                         @click.capture.stop='updateFlowConfig()'
                         :disabled="isLoading || configurationEmpty || !configurationChanged || !configurationValid"
                  />
                </div>
              </div>
            </q-card-section>
          </q-card>
        </q-expansion-item>
      </div>

      <div class="app-expansion-section">
        <q-expansion-item
            class="q-mb-md q-mx-md"
            v-model="expandInput"
            switch-toggle-side
            default-opened
            data-cy="sectionInput"
        >

          <template v-slot:header>
            <q-item-section>
              <div class="row no-wrap one-liner">
                <div class="one-liner-child">{{ $t('flow.overview.detail.input.title') }}</div>
                <q-badge class="q-ml-sm q-pb-xs self-center items-center">{{ getInputMappings.length }}</q-badge>
              </div>
            </q-item-section>
            <q-item-section side>
              <q-btn
                  flat dense icon-right="add"
                  class="q-px-sm justify-end app-action-btn"
                  :label="$t('flow.overview.detail.addJdmShort')"
                  :title="$t('flow.overview.detail.addJdm')"
                  @click.capture.stop="createJdmModal('input')"
                  :disabled="isLoading"
                  data-cy="buttonAddInputJdm"
              />
            </q-item-section>
          </template>
          <q-card class="bg-transparent">
            <q-card-section>
              <div class="app-jdm-section">
                <jdm-item
                    v-if="!(!isLoading && getInputMappings.length <= 0)"
                    v-for="mapping in getInputMappings"
                    :jdm="mapping"
                    :key="mapping.id"
                    :flow="currentFlow"
                    @reload="pageLoad($event)"
                />
                <div v-if="(!isLoading && getInputMappings.length <= 0)" class="app-jdm-empty">
                  <p class="q-my-sm text-light">{{ $t('flow.overview.detail.input.empty') }}</p>
                </div>
              </div>
            </q-card-section>
          </q-card>
        </q-expansion-item>
      </div>

      <div class="app-expansion-section">
        <q-expansion-item
            class="q-mb-md q-mx-md"
            v-model="expandTransform"
            switch-toggle-side
            default-opened
            data-cy="sectionTransformation"
        >

          <template v-slot:header>
            <q-item-section>
              <div class="row no-wrap one-liner">
                <div class="one-liner-child">{{ $t('flow.overview.detail.transformation.title') }}</div>
                <q-badge class="q-ml-sm q-pb-xs self-center items-center">{{ getTransformationMappings.length }}</q-badge>
              </div>
            </q-item-section>
            <q-item-section side>
              <q-btn
                  flat dense icon-right="add"
                  class="q-px-sm justify-end app-action-btn"
                  :label="$t('flow.overview.detail.addJdmShort')"
                  :title="$t('flow.overview.detail.addJdm')"
                  @click.capture.stop="createJdmModal('transformations')"
                  :disabled="isLoading"
                  data-cy="buttonAddTransformationJdm"
              />
            </q-item-section>
          </template>
          <q-card class="bg-transparent">
            <q-card-section>
              <div class="app-jdm-section">
                <jdm-item v-if="!(!isLoading && getTransformationMappings.length <= 0)"
                          v-for="mapping in getTransformationMappings"
                          :jdm="mapping"
                          :key="mapping.id"
                          :flow="currentFlow"
                          @reload="pageLoad($event)"
                />
                <div v-if="(!isLoading && getTransformationMappings.length <= 0)" class="app-jdm-empty">
                  <p class="q-my-sm text-light">{{ $t('flow.overview.detail.transformation.empty') }}</p>
                </div>
              </div>
            </q-card-section>
          </q-card>
        </q-expansion-item>
      </div>

      <div class="app-expansion-section">
        <q-expansion-item
            class="q-mb-md q-mx-md"
            v-model="expandOutput"
            switch-toggle-side
            default-opened
            data-cy="sectionOutput"
        >

          <template v-slot:header>
            <q-item-section>
              <div class="row no-wrap one-liner">
                <div class="one-liner-child">{{$t('flow.overview.detail.output.title')}}</div>
                <q-badge class="q-ml-sm q-pb-xs self-center items-center" color="primary">{{ getOutputMappings.length }}</q-badge>
              </div>
            </q-item-section>
            <q-item-section side>
              <q-btn
                  flat dense icon-right="add"
                  class="q-px-sm justify-end app-action-btn"
                  :label="$t('flow.overview.detail.addJdmShort')"
                  :title="$t('flow.overview.detail.addJdm')"
                  @click.capture.stop="createJdmModal('output')"
                  :disabled="isLoading"
                  data-cy="buttonAddOutputJdm"
              />
            </q-item-section>
          </template>
          <q-card class="bg-transparent">
            <q-card-section>
              <div class="app-jdm-section">
                <jdm-item
                    v-if="!(!isLoading && getOutputMappings.length <= 0)"
                    v-for="mapping in getOutputMappings"
                    :jdm="mapping"
                    :key="mapping.id"
                    :flow="currentFlow"
                    @reload="pageLoad($event)"
                />
                <div v-if="(!isLoading && getOutputMappings.length <= 0)" class="app-jdm-empty">
                  <p class="q-my-sm text-light">{{ $t('flow.overview.detail.output.empty') }}</p>
                </div>
              </div>
            </q-card-section>
          </q-card>
        </q-expansion-item>
      </div>
    </template>

    <export-flow-modal
      v-model="showExportFlowModal"
      :flow="$route.params.id"
    />

    <flow-modal
      v-model="showFlowModal"
      :flow="currentFlow"
      @updated="handleUpdated"
    />

    <end-flow-modal
      v-model="showEndFlowModal"
      page="flow"
      :flow-id="currentFlow?.lastFlowExecutionId"
      :flow-name="currentFlow?.name"
      @success="handleFlowEnded"
    />

    <create-job-dispatcher-mapping-modal
      v-model="showJdmModal"
      :flow="currentFlow"
      :process-step="processStep"
      @created="handleJdmCreated"
    />
  </q-page>
</template>

<style lang="scss">
.q-page-container .q-page .app-dashcard-container .app-dashcard.app-flow-execution-card {
  margin-left: unset;
}
.app-expansion-section .one-liner {
  max-width: 100%;
  &-child {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: 600;
  }
}

.app-flow-detail {
  .app-jdm-item + .app-jdm-item {
    margin-top: 1rem;
  }
}

.app-flow-execution-card {
  padding: unset;
}

.app-flow-detail .controls .app-action-btn {
  margin-top: 1rem;
  @media (min-width: $breakpoint-sm) {
    margin-top: unset;
  }
}

.app-jdm-section {
  padding: 0 .8rem;
  .app-jdm-empty {
    padding: 0 .5rem;
  }
}

body.body--dark {
  .app-jump-to-execution {
    color: $light;
    .q-icon {
      filter: invert(1);
    }
  }
}
@media (max-width: $breakpoint-xs) {
  .last-execution-info {
    justify-content: flex-start;

    &>div { margin-right: 1rem; }
  }
}

.app-flow-detail .app-headline-container .app-edit-flowname {
  img {
    width: 1rem;
    height: 1rem;
    filter: $secondarize;
  }

  &:hover img {
    filter: invert(1);
  }
}
</style>
