<script>
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'FlowExecutionBadges',
    props: {
      prop: {
        type: Object,
        required: true
      },
      getNodeProgress: {
        type: Function,
        required: true
      },
      getNodeProgressBySum: {
        type: Function,
        required: true
      },
      getNodeItems: {
        type: Function,
        required: true
      },
      calcHeaderProgressDifferently: {
        type: Boolean,
        required: true
      }
    },
    methods: {
      label(progress, isPercent) {
        if(progress === null) return '-';
        if(isPercent) return progress + '%';
        return progress;
      },
      statusColor(progress) {
        if(progress === null || progress?.toString() === '0') return 'negative';
        if(parseFloat(Math.floor(progress)).toFixed(0) === '100') return 'positive';
        return 'warning';
      }
    },
    computed: {
      node() {
        return this.$props.prop.node;
      },
      processedCount() {
        return this.$props.prop.node.processedCount;
      },
      totalCount() {
        return this.$props.prop.node.totalCount;
      },
      itemLabel() {
        const progress = this.$props.getNodeItems(this.processedCount, this.totalCount);
        return this.label(progress, false);
      },
      progressLabel() {
        const progress = this.$props.getNodeProgress(this.processedCount, this.totalCount);
        return this.label(progress, true);
      },
      progressLabelBySum() {
        const progress = this.$props.getNodeProgressBySum(this.$props.prop.node);
        return this.label(progress, true);
      },
      itemLabelBySum() {
        const progress = this.$props.getNodeProgressBySum(this.$props.prop.node, 'processed') + ' / ' + this.$props.getNodeProgressBySum(this.$props.prop.node, 'total');
        return this.label(progress, false);
      },
      progressColor() { // Can be used for both progress and items badge
        const progress = this.node.done ? 100 : this.$props.getNodeProgress(this.processedCount, this.totalCount);
        return this.statusColor(progress);
      },
      progressColorBySum() {
        const progress = this.node.done ? 100 : this.$props.getNodeProgressBySum(this.node);
        return this.statusColor(progress);
      }
    }
  })
</script>

<template>
  <div class="app-flowexecution-value flex inline" style="margin: 0 .5rem;">

    <template v-if="!this.calcHeaderProgressDifferently">
      <q-badge
          class="app-flow-status-badge app-progress-badge justify-center"
          :color="progressColor"
          :label="progressLabel"
      />
    </template>
    <template v-if="this.calcHeaderProgressDifferently">
      <q-badge
          class="app-flow-status-badge app-progress-badge justify-center"
          :color="progressColorBySum"
          :label="progressLabelBySum"
      />
      <!-- Note: If you want to calculate total sub node process count instead of node AMOUNT, use this badge -->
      <!-- <q-badge
          v-if="getNodeProgressBySum(prop.node, 'total') !== null"
          class="app-flow-status-badge justify-center"
          style="margin: 0 .5rem;"
          :color="progressColorBySum"
          :label="itemLabelBySum"
      />-->
    </template>

    <q-badge
        class="app-flow-status-badge app-items-badge justify-center"
        style="margin: 0 .5rem;"
        :color="progressColor"
        :label="itemLabel"
    />
  </div>
</template>

<style lang="scss">
  .app-flow-status-badge.active {
    border: 1px solid $primary;
  }
</style>
