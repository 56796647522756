<script>
  export default {
    name: 'ConfirmationDialog',

    props: {
      modelValue: {
        type: Boolean
      },

      title: {
        type: String,
        default: 'Confirm to proceed.'
      },

      type: {
        type: String,
        required: false,
        default: 'primary'
      }
    },

    emits: ['close', 'confirm'],

    methods: {
      confirm() {
        this.$emit('confirm')
      },

      closeDialog(isCancel) {
        this.$emit('close', isCancel)
      }
    }
  }
</script>

<template>
  <q-dialog
    :model-value="modelValue"
    @hide="closeDialog(false)"
    @escape-key="closeDialog(true)"
  >
    <q-card>
      <q-card-section class="row items-center q-ma-sm">
        <span class="text-h6 text-weight-medium text-grey-8">{{ title }}</span>
      </q-card-section>

      <q-card-section class="q-ma-sm">
        <slot name="content" />
      </q-card-section>

      <q-card-actions align="right">
        <q-btn
          v-close-popup
          flat
          :label="$t('common.cancel')"
          @click="closeDialog(true)"
        />

        <q-btn
          v-close-popup
          :label="$t('common.confirm')"
          :color="type"
          @click="confirm"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>
